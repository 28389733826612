import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import OrderItemContainer from './orderitemcontainer';
import Order from '../domain/order';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import OrderMassEditDialog from './ordermasseditdialog';
import User from '../domain/user';
import OrderConfirmationMailInputDialog from './orderConfirmationMailInfoInputDialog';
import moment from 'moment';
import { cloneDeep, find } from 'lodash';
import CombinedOrdersInput from './combinedordersinput';
import OrderSendStatusDialog from './ordersendstatusdialog';
import {
    productTypeMakeCapacityReservations,
    productTypeUpdateCapacityReservationsIfNeeded,
    earliestDeliveryDateForOrder,
    removeCapacityReservations,
} from './capacityplannerproducttypes';
import { updateSelectedDraftOrders } from '../modules/orderdata';
import PalletInfoSelectorDialog from './palletinfoselectordialog';
import Logger from '../common/logger';
import { returnAllAndAllowedPalletCodesInOrders } from './palletcodeutil';
import ConfirmationDialog from '../common/confirmationdialog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import OrderRow from '../domain/orderrow';
import FilterProduct from '../domain/filterproduct';
import { ProductBundle } from '../domain/productbundle';
import dayjs from 'dayjs';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    selectAll: {
        marginLeft: '8px',
    },
}));

export default function ShoppingBasket(props) {
    const classes = useStyles();
    const store = useStore();
    const dispatch = useDispatch();
    const [sendingOrdersModalOpen, setSendingOrdersModalOpen] = useState(false);
    const [combineOrders, setCombineOrders] = useState(false);
    const [combinedOrder, setCombinedOrder] = useState({
        deliveryTime: '',
        deliveryTerms: '',
        ordererName: props.userProfile.name,
        ordererTel: props.userProfile.phoneNumber,
        reference: '',
        confirmationEmail: props.userProfile.username,
        orderType: Order.OrderTypeNormal(),
        generalInfo: '',
        deliveryMethod: null,
        earlyDeliveryTime: '',
    });
    const formStatusById = React.useRef({});
    const formStatusUpdateTimerHandle = React.useRef(null);
    const [massEditOpen, setMassEditOpen] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [inputError, setInputError] = useState({
        toimitusaika: true,
        toimitusehdot: true,
        'tilaaja-nimi': true,
        'tilaaja-puh': true,
        'order-reference': true,
        'confirmation-email': true,
    });
    const [earliestDeliveryDateForCombinedOrder, setEarliestDeliveryDateForCombinedOrder] = useState(null);
    const [deliveryDateDetailsCombinedOrder, setDeliveryDateDetailsCombinedOrder] = useState(null);
    const [anyFormsWithErrors, setAnyFormsWithErrors] = useState(true);
    const [editOrderPalletInfo, setEditOrderPalletInfo] = useState(false);
    const [usedPalletCodes, setUsedPalletCodes] = useState([]);
    const [allowedPalletCodes, setAllowedPalletCodes] = useState([]);
    const [palletInfoEditOrder, setPalletInfoEditOrder] = useState(null);
    const [palletInfoEditFos, setPalletInfoEditFos] = useState(null);
    const [confirmDeliveryTimeResetOpen, setConfirmDeliveryTimeResetOpen] = useState(false);

    const pendingOrders = props.pendingOrders;
    const customerView = props.customerView;
    const sendingOrdersInProgress = props.sendingOrdersInProgress;
    const savingCombinedOrder = props.savingCombinedOrder;
    const userProfile = props.userProfile;
    const orderSendFailed = props.orderSendFailed;

    const customerDescriptions = useSelector((state) => state.customerdata.customerDescriptions);
    const customersById = useSelector((state) => state.customerdata.customersById);
    const selected = useSelector((state) => state.orderdata.selectedDraftOrders);

    const products = useSelector((state) => state.productdata.filterProducts);
    const bundles = useSelector((state) => state.productdata.productBundles);

    const [massEditDefaultValues, setMassEditDefaultValues] = useState({});

    const { t, i18n } = useTranslation();
    const userLanguage = i18n.language;

    const allowPricing = !customerView || _.includes(userProfile.roles, User.RoleAllowPricing());
    const orderList =
        pendingOrders && pendingOrders.length > 0 && selected
            ? _.sortBy(
                  pendingOrders.map((o) => {
                      const order = o;
                      order.selectedInverted = !selected[o.id];
                      return order;
                  }),
                  ['selectedInverted', 'location.name']
              )
            : [];

    const updateAnyFormsWithErrors = useCallback(() => {
        if (combineOrders) {
            setAnyFormsWithErrors(!isFormOk(inputError));
        } else {
            let errors = false;
            const keys = Object.keys(formStatusById.current).filter((k) => selected[k] === true);
            if (keys.length === 0) {
                errors = true;
            } else {
                for (let k of keys) {
                    if (formStatusById.current[k] === false) {
                        errors = true;
                    }
                }
            }
            setAnyFormsWithErrors(errors);
        }
    }, [combineOrders, inputError, selected]);

    const selectedOrders = useCallback(() => {
        if (pendingOrders && pendingOrders.length > 0 && selected) {
            return pendingOrders.filter((o) => selected[o.id] === true);
        }
        return [];
    }, [pendingOrders, selected]);

    const ordersCanBeCombined = useCallback(() => {
        const selectOrders = selectedOrders();
        if (!selectOrders || selectOrders.length < 2) return false;

        if (
            selectOrders.filter((o) => o.combinedOrderInfoBase64 !== selectOrders[0].combinedOrderInfoBase64).length > 0
        ) {
            return false;
        }
        return true;
    }, [selectedOrders]);

    const updateFormStatus = useCallback(() => {
        const keys = Object.keys(formStatusById.current);
        let newFormStatusById = {};

        for (let k of keys) {
            newFormStatusById[k] = formStatusById.current[k];
        }

        formStatusById.current = newFormStatusById;

        if (!ordersCanBeCombined() && combineOrders) {
            setCombineOrders(false);
        }
    }, [combineOrders, ordersCanBeCombined]);

    useEffect(() => {
        if (pendingOrders && pendingOrders.length > 0 && selected === null) {
            const newSelected = {};
            for (const o of pendingOrders) {
                newSelected[o.id] = true;
            }
            updateFormStatus();
            updateSelectedDraftOrders(newSelected)(dispatch);
        } else if (pendingOrders && pendingOrders.length > 0) {
            updateFormStatus();
            updateAnyFormsWithErrors();
        }
    }, [
        pendingOrders,
        dispatch,
        updateAnyFormsWithErrors,
        selected,
        combineOrders,
        ordersCanBeCombined,
        updateFormStatus,
    ]);

    useEffect(() => {
        setInputError(getInputErrors(combinedOrder));
    }, [combinedOrder]);

    useEffect(() => {
        updateAnyFormsWithErrors();
    }, [inputError, updateAnyFormsWithErrors]);

    useEffect(() => {
        async function getEarliestDeliveryTime() {
            let earliest = null;

            const earliestTimes = await earliestDeliveryDateForOrder(store, selectedOrders());
            setDeliveryDateDetailsCombinedOrder(earliestTimes);
            if (
                earliestTimes == null ||
                _.some(earliestTimes, (t) => {
                    return t.earliestDeliveryDate == null;
                })
            ) {
                earliest = null;
                //setEarliestDeliveryDateDetails(earliestTimes);
            } else {
                earliest = _.maxBy(earliestTimes, (t) => dayjs(t.earliestDeliveryDate).valueOf()).earliestDeliveryDate;
                //setEarliestDeliveryDateDetails(earliestTimes);
            }

            if (earliest) {
                setEarliestDeliveryDateForCombinedOrder(earliest);
                if (
                    combinedOrder.deliveryTime &&
                    moment(combinedOrder.deliveryTime).isValid() &&
                    moment(earliest).isAfter(moment(combinedOrder.deliveryTime), 'day')
                ) {
                    setConfirmDeliveryTimeResetOpen(true);
                }
            }
        }
        if (combineOrders) {
            getEarliestDeliveryTime();
        }
        updateAnyFormsWithErrors();
    }, [combineOrders, combinedOrder, selected, pendingOrders, selectedOrders, updateAnyFormsWithErrors, store]);

    const getEarliestDeliveryTimeForMassEdit = async (orderType) => {
        let earliestDate = null;
        let earliestDateDetails = null;
        const earliestTimes = await earliestDeliveryDateForOrder(store, selectedOrders());
        earliestDateDetails = earliestTimes;
        if (
            _.some(earliestTimes, (t) => {
                return t.earliestDeliveryDate === null;
            })
        ) {
            earliestDate = null;
        } else {
            earliestDate = _.maxBy(earliestTimes, (t) => dayjs(t.earliestDeliveryDate).valueOf()).earliestDeliveryDate;
        }

        return {
            earliestDate,
            earliestDateDetails,
        };
    };

    const isFormOk = (errors) => {
        const keys = Object.keys(errors);
        let formOk = true;
        for (let k of keys) {
            if (errors[k] === true) {
                formOk = false;
                break;
            }
        }
        return formOk;
    };

    const getInputErrors = (values) => {
        const status = {
            toimitusaika:
                values.deliveryTime == null ||
                values.deliveryTime.length === 0 ||
                !moment(values.deliveryTime).isValid() ||
                moment(values.deliveryTime).isBefore(moment(), 'day'),
            toimitusehdot: values.deliveryTerms == null || values.deliveryTerms.length === 0,
            'tilaaja-nimi': values.ordererName == null || values.ordererName.length === 0,
            'tilaaja-puh': values.ordererTel == null || values.ordererTel.length === 0,
            'order-reference': values.reference == null || values.reference.length === 0,
            'confirmation-email': values.confirmationEmail == null || values.confirmationEmail.length === 0,
            deliveryMethod: values.deliveryMethod == null || values.deliveryMethod.length === 0,
        };
        return status;
    };

    const removeOrder = (id) => {
        props.removeOrder(id);
    };

    const saveOrder = (order) => {
        props.saveOrder(order);
    };

    const saveOrderShallow = async (order) => {
        await props.saveOrderShallow(order);
    };

    const saveFilterOrder = async (order, fOrder) => {
        await props.saveFilterOrder(order, fOrder);
    };

    const onOrderConfirmationMailInfo = async () => {
        if (customerView) await onSendOrders(null, userLanguage);
        else setConfirmationDialogOpen(true);
    };

    const onConfirmationCancel = () => {
        setConfirmationDialogOpen(false);
    };

    const onConfirmationAccept = async (language, confirmationOptions) => {
        //note: dialog close in onSendOrders
        await onSendOrders(confirmationOptions, language);
    };

    const onSendOrders = async (confirmationOptions, language) => {
        setSendingOrdersModalOpen(true);
        setConfirmationDialogOpen(false);

        // <HACK>
        // Tässä on tilapäinen veivaus, joka korjaa tilanteen asiakastilausten käsittelyssä:
        // - lisätään koriin läjä tilauksia ja tilataan samalla istumalla (ei selaimen refreshiä, uudelleenlatausta)
        // Ongelma on oikeasti backedin palauttamassa datassa kun lisätään läjä tilauksia. Sieltä tulee hieman epäkuranttia
        // dataa ainakin tässä tapauksessa. Ja tämä data sitten aiheuttaa de-serialisointivirheen päivämäärissä (pvm null tjsp)
        // Mutta nollataan kyseinen ongelmallinen kohta datassa koska se on merkityksetön joka tapauksessa. Datasisältö voisi
        // olla muutoinkin kevyempi kun päivitetään tilauksen tietoja.. ts. turha kuskata location & customer olioita täydessä
        // komeudessaan mukana (ja vieläpä kaikkien tilausten sisässä samat pahimmillaan).
        // Tässä oikaistu vielä senkin verran, että ei edes kloonata order olioita tässä vaan mutatoidaan tylysti dataa, mutta
        // tämäkään ei haittaa koska "treeData":aa ei tilausten käsittelyssä tarvita missään vaiheessa.
        const toSend = selectedOrders();
        for (const o of toSend) {
            o.customer.treeData = null;
        }
        //</HACK>

        if (combineOrders) {
            let orders = [];
            for (let o of toSend) {
                let order = new Order(o);
                order.deliveryTime = combinedOrder.deliveryTime;
                order.deliveryTerms = combinedOrder.deliveryTerms;
                order.ordererName = combinedOrder.ordererName;
                order.ordererTel = combinedOrder.ordererTel;
                order.reference = combinedOrder.reference;
                order.confirmationEmail = combinedOrder.confirmationEmail;
                order.packingInstructions = combinedOrder.packingInstructions;
                order.orderType = combinedOrder.orderType;
                order.generalInfo = combinedOrder.generalInfo;
                order.deliveryMethod = combinedOrder.deliveryMethod;
                order.earlyDeliveryTime = combinedOrder.earlyDeliveryTime;
                orders.push(order);
            }
            try {
                // TODO in new mode, let the order sender make all reservation stuff and do nothing here
                let reservations = [];

                if (reservations) {
                    props.sendOrdersCombined(orders, confirmationOptions, language, reservations);
                } else {
                    setSendingOrdersModalOpen(false);
                    toast.error(t('order.orderSendFailedRetry'), { autoClose: 5000 });
                }
            } catch (err) {
                console.log(err);
                setSendingOrdersModalOpen(false);
                toast.error(t('order.orderSendFailedRetry'), { autoClose: 5000 });
            }
        } else {
            props.sendOrders(toSend, confirmationOptions, language);
        }
    };

    const okBtn = () => {
        setSendingOrdersModalOpen(false);
    };

    const isOrderSendDone = () => {
        return !sendingOrdersInProgress && !savingCombinedOrder;
    };

    const onClickComboOrder = async () => {
        const newCombinedOrder = cloneDeep(combinedOrder);
        newCombinedOrder.deliveryTerms = combinedOrder.deliveryTerms === '' ? selectedOrders()[0].deliveryTerms : '';
        newCombinedOrder.reference = combinedOrder.reference === '' ? selectedOrders()[0].reference : '';

        // fix this to use batch save
        if (!combineOrders) {
            for (const o of selectedOrders()) {
                if (o.deliveryTime) {
                    const newOrder = new Order(o);
                    newOrder.deliveryTime = null;
                    newOrder.earlyDeliveryTime = null;
                    props.saveOrderShallow(newOrder);
                    removeCapacityReservations(store, o.id);
                }
            }
        }
        setCombinedOrder(newCombinedOrder);
        setCombineOrders(!combineOrders);
    };

    const onDateChangeCombinedOrder = (date) => {
        const selectedDate = moment(date);
        let newDate = null;
        let earlyDate = combinedOrder.earlyDeliveryTime;
        if (selectedDate.isValid()) {
            selectedDate.hour(12).minute(0).second(0);
            if (selectedDate.isSameOrAfter(earliestDeliveryDateForCombinedOrder, 'day')) {
                newDate = selectedDate;
            }
        }
        if (props.customerView) {
            earlyDate = newDate;
        } else if (newDate === null) {
            earlyDate = null;
        } else if (moment(earlyDate).isAfter(moment(newDate), 'day')) {
            earlyDate = newDate;
        }
        const newCombinedOrder = cloneDeep(combinedOrder);
        newCombinedOrder.deliveryTime = newDate;
        newCombinedOrder.earlyDeliveryTime = earlyDate;
        setCombinedOrder(newCombinedOrder);
    };

    const onEarlyDateChange = (date) => {
        const selectedDate = moment(date);
        let newDate = null;
        if (selectedDate.isValid()) {
            newDate = selectedDate.hour(12).minute(0).second(0);
            if (moment(newDate).isAfter(moment(combinedOrder.deliveryTime))) {
                toast.error(t('order.earliestDeliveryDateCannotByLaterThanDeliveryDate'), { autoClose: 5000 });
                newDate = null;
            }
        }
        const newCombinedOrder = cloneDeep(combinedOrder);
        newCombinedOrder.earlyDeliveryTime = newDate;
        setCombinedOrder(newCombinedOrder);
    };

    const onOrderInfoBlur = (event) => {
        const newCombinedOrder = cloneDeep(combinedOrder);
        const target = event.target;
        const value = target.value;
        const name = target.name;
        switch (name) {
            case 'combined-order-toimitusehdot':
                newCombinedOrder.deliveryTerms = value;
                break;
            case 'combined-order-tilaaja-nimi':
                newCombinedOrder.ordererName = value;
                break;
            case 'combined-order-tilaaja-puh':
                newCombinedOrder.ordererTel = value;
                break;
            case 'combined-order-reference':
                newCombinedOrder.reference = value;
                break;
            case 'combined-order-packinginstructions':
                newCombinedOrder.packingInstructions = value;
                break;
            case 'OrderType':
                newCombinedOrder.orderType = value;
                break;
            case 'combined-order-general-info':
                newCombinedOrder.generalInfo = value;
                break;
            case 'combined-order-confirmation-email':
                newCombinedOrder.confirmationEmail = value;
                break;
            case 'DeliveryMethod':
                const param = props.parameters['DeliveryMethod'].find((p) => p.id === value);
                newCombinedOrder.deliveryMethod = param ? param.value : null;
                if (param) {
                    newCombinedOrder.deliveryTime = null;
                    newCombinedOrder.earlyDeliveryTime = null;
                }
                break;
            default:
                break;
        }
        setCombinedOrder(newCombinedOrder);
    };

    const updateOrderFormState = (status, orderId) => {
        const newFormStatus = cloneDeep(formStatusById.current);
        newFormStatus[orderId] = status;
        formStatusById.current = newFormStatus;

        if (formStatusUpdateTimerHandle.current) clearTimeout(formStatusUpdateTimerHandle.current);
        formStatusUpdateTimerHandle.current = setTimeout(() => {
            updateFormStatus();
            formStatusUpdateTimerHandle.current = null;
            updateAnyFormsWithErrors();
        }, 500);
    };

    const anySamePortfolioOrderSelected = () => {
        return selectedOrders().length > 0 && _.uniqBy(selectedOrders(), 'portfolioId').length === 1;
    };

    const onMassEdit = () => {
        if (selectedOrders().length === 0) return;
        const massEditDefaults = {
            deliveryTerms: selectedOrders()[0].deliveryTerms,
            ordererName: selectedOrders()[0].ordererName,
            ordererTel: selectedOrders()[0].ordererTel,
            reference: selectedOrders()[0].reference,
            confirmationEmail: selectedOrders()[0].confirmationEmail,
            deliveryMethod: selectedOrders()[0].deliveryMethod,
        };
        for (const o of selectedOrders()) {
            if (massEditDefaults.deliveryTerms !== o.deliveryTerms) {
                massEditDefaults.deliveryTerms = null;
            }
            if (massEditDefaults.ordererName !== o.ordererName) {
                massEditDefaults.ordererName = null;
            }
            if (massEditDefaults.ordererTel !== o.ordererTel) {
                massEditDefaults.ordererTel = null;
            }
            if (massEditDefaults.reference !== o.reference) {
                massEditDefaults.reference = null;
            }
            if (massEditDefaults.confirmationEmail !== o.confirmationEmail) {
                massEditDefaults.confirmationEmail = null;
            }
            if (massEditDefaults.deliveryMethod !== o.deliveryMethod) {
                massEditDefaults.deliveryMethod = null;
            }
        }

        setMassEditDefaultValues(massEditDefaults);
        setMassEditOpen(true);
    };

    const onCancelMassEdit = () => {
        setMassEditOpen(false);
    };

    const onSaveMassEditData = async (data) => {
        setMassEditOpen(false);
        let orderIdsToUpdateCapa = [];

        const ordersToSave = selectedOrders().map((o) => {
            const newOrder = new Order(o);
            newOrder.deliveryTerms = data.deliveryTerms != null ? data.deliveryTerms : newOrder.deliveryTerms;
            newOrder.ordererName = data.ordererName != null ? data.ordererName : newOrder.ordererName;
            newOrder.ordererTel = data.ordererTel != null ? data.ordererTel : newOrder.ordererTel;
            newOrder.reference = data.reference != null ? data.reference : newOrder.reference;
            newOrder.confirmationEmail =
                data.confirmationEmail != null ? data.confirmationEmail : newOrder.confirmationEmail;
            newOrder.generalInfo = data.generalInfo != null ? data.generalInfo : newOrder.generalInfo;
            if (data.deliveryTime && !moment(newOrder.deliveryTime).isSame(data.deliveryTime, 'day')) {
                orderIdsToUpdateCapa.push(newOrder.id);
            }
            newOrder.deliveryTime = data.deliveryTime != null ? data.deliveryTime : newOrder.deliveryTime;
            newOrder.earlyDeliveryTime =
                data.earlyDeliveryTime != null ? data.earlyDeliveryTime : newOrder.earlyDeliveryTime;
            if (
                data.orderType &&
                data.orderType !== newOrder.orderType &&
                !orderIdsToUpdateCapa.includes(newOrder.id)
            ) {
                orderIdsToUpdateCapa.push(newOrder.id);
            }
            newOrder.orderType = data.orderType != null ? data.orderType : newOrder.orderType;
            if (data.deliveryMethod && data.deliveryMethod.id) {
                newOrder.deliveryMethod = props.parameters['DeliveryMethod'].find(
                    (p) => p.id === data.deliveryMethod.id
                ).value;
            }

            if (!customerView) {
                newOrder.packingInstructions =
                    data.packingInstructions != null ? data.packingInstructions : newOrder.packingInstructions;
            }
            return newOrder;
        });

        for (let orderItem of ordersToSave) {
            if (orderIdsToUpdateCapa.includes(orderItem.id)) {
                await removeCapacityReservations(store, orderItem.id);
                await onReserveCapacity(orderItem.deliveryTime, orderItem);
            }
        }
        props.saveOrderShallowBatch(ordersToSave);
    };

    const selectOrder = (id) => {
        const newSelects = cloneDeep(selected);
        newSelects[id] = !newSelects[id];
        if (combineOrders) {
            const selectedOrder = pendingOrders.filter((o) => o.id === id)[0];
            if (selectedOrder.deliveryTime) {
                const newOrder = new Order(selectedOrder);
                newOrder.deliveryTime = null;
                props.saveOrderShallow(newOrder);
                removeCapacityReservations(store, selectedOrder.id);
            }
        }
        updateSelectedDraftOrders(newSelects)(dispatch);
    };

    const clickSelectAll = (e) => {
        e.stopPropagation();
        if (allOrdersSelected()) {
            updateSelectedDraftOrders({})(dispatch);
        } else {
            const newselected = {};
            for (const o of pendingOrders) {
                newselected[o.id] = true;
            }
            updateSelectedDraftOrders(newselected)(dispatch);
        }
    };

    const allOrdersSelected = () => {
        if (!pendingOrders && !selected) return false;
        return pendingOrders.length === selectedOrders().length;
    };

    const onReserveCapacity = async (estimatedDeliveryDate, orderItem) => {
        return await productTypeMakeCapacityReservations(store, [orderItem], estimatedDeliveryDate);
    };

    const onRemoveCapacityReservations = async (orderId) => {
        return await removeCapacityReservations(store, orderId);
    };

    const onUpdateCapacityReservationsIfNeeded = async (order, deliveryDate) => {
        return await productTypeUpdateCapacityReservationsIfNeeded(store, order);
    };

    const onEditOrderPalletInformation = (orderId) => {
        const orderInScope = selectedOrders().find((o) => o.id === orderId);
        if (orderInScope) {
            const data = returnAllAndAllowedPalletCodesInOrders(orderInScope, selectedOrders(), pendingOrders);
            setUsedPalletCodes(data[0]);
            setAllowedPalletCodes(data[1]);
            setPalletInfoEditOrder(orderInScope);
            setEditOrderPalletInfo(true);
        }
    };

    const onEditFoPalletInformation = (orderId, foIds) => {
        const orderInScope = selectedOrders().find((o) => o.id === orderId);
        const fos = orderInScope.orderedFilters.filter((fo) => foIds.includes(fo.id));
        setPalletInfoEditFos(fos);
        onEditOrderPalletInformation(orderId);
    };

    const cancelEditPalletInfo = () => {
        setEditOrderPalletInfo(false);
        setPalletInfoEditFos(null);
        setPalletInfoEditOrder(null);
    };

    const savePalletInfo = (palletCode, orderId, fos) => {
        const orderInScope = pendingOrders.find((o) => o.id === orderId);
        if (orderInScope) {
            const newOrder = _.cloneDeep(orderInScope);
            if (fos && fos.length > 0) {
                for (const fo of newOrder.orderedFilters.filter((fo) => fos.find((foi) => foi.id === fo.id))) {
                    fo.palletInformation = palletCode;
                }
            } else {
                for (const fo of newOrder.orderedFilters) {
                    fo.palletInformation = palletCode;
                }
            }
            props.saveOrder(newOrder);
        } else {
            Logger.LogError(
                `Trying to set pallet info, but order in scope not found, id:${orderId}, palletcode:${palletCode}`,
                ''
            );
        }
        setEditOrderPalletInfo(false);
        setPalletInfoEditFos(null);
        setPalletInfoEditOrder(null);
    };

    const getParametersObject = (type, text) => {
        return props.parameters[type].find((p) => p.value === text);
    };

    const addProductWithId = (orderId, productId) => {
        let product = find(products, { id: productId });
        if (!product) product = find(bundles, { id: productId });

        if (!product) {
            toast.error(t('order.errorProductNotFound', { prod: productId }), { autoClose: 5000 });
            return;
        }
        addProductInternal(orderId, product);
    };

    const addProductWithCode = (orderId, productCode) => {
        const product = find(products, { productCode: productCode });
        if (!product) {
            toast.error(t('order.errorProductNotFound', { prod: productCode }), { autoClose: 5000 });
            return;
        }
        addProductInternal(orderId, product);
    };

    const addProductInternal = (orderId, product) => {
        let order = find(pendingOrders, function (o) {
            return o.id === orderId;
        });

        OrderRow.NewObject().then((ford) => {
            ford.storageId = null;
            ford.product = product.productType() === 'fp' ? new FilterProduct(product) : null;
            ford.productBundle = product.productType() === 'bundle' ? new ProductBundle(product) : null;
            ford.machineName = '';
            ford.machineLocation = '';
            ford.machineArea = '';
            ford.machineAdditionalInfo = '';
            ford.count = 1;
            ford.discount = 0;
            ford.totalPriceWithoutVat =
                product.productType() === 'fp' ? product.priceWithoutVat : product.priceWithoutVat();
            ford.unitPriceWithoutVat =
                product.productType() === 'fp' ? product.priceWithoutVat : product.priceWithoutVat();
            ford.viewOrder = order.orderedFilters.length;
            ford.machineViewOrder = _.maxBy(order.orderedFilters, (f) => f.machineViewOrder).machineViewOrder + 1;
            ford.palletInformation = '';
            ford.filterAdditionalInfo = '';

            const newOrd = cloneDeep(order);
            newOrd.orderedFilters.push(ford);
            props.saveOrder(newOrd);
        });
    };

    const confirmResetDeliveryTime = async () => {
        //setOrderChanges({ ...orderChanges, deliveryTime: null });
        //saveChanges();
        //const promises = props.orders.map((o) => props.removeCapacityReservations(o.id));
        //Promise.all(promises);

        const newCombinedOrder = cloneDeep(combinedOrder);
        newCombinedOrder.deliveryTime = null;
        newCombinedOrder.earlyDeliveryTime = null;
        setCombinedOrder(newCombinedOrder);

        setTimeout(() => {
            setConfirmDeliveryTimeResetOpen(false);
        }, 200);
    };

    const checkIfPrivateCustomerOrdersOk = () => {
        const selectedOrdersList = selectedOrders();
        if (selectedOrdersList.length === 0) return true;

        const normalOrders = _.filter(selectedOrdersList, { orderToPrivateCustomer: false });
        const privateCustOrders = _.filter(selectedOrdersList, { orderToPrivateCustomer: true });

        if (normalOrders.length && privateCustOrders.length) return false;
        if (privateCustOrders.length > 0 && !combineOrders) return false;

        return true;
    };

    if (!pendingOrders || pendingOrders.length === 0) {
        return <div>{t('order.noDraftOrders')}</div>;
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h3>{t('general.order')}</h3>
                    {!checkIfPrivateCustomerOrdersOk() && (
                        <div>
                            <Alert severity="info">{t('order.privateCustomerOrderingNotice')}</Alert>
                        </div>
                    )}
                    <hr />
                    {orderList && orderList.length > 0 && (
                        <FormControlLabel
                            className={classes.selectAll}
                            onClick={clickSelectAll}
                            name="select-all-rows"
                            control={<Checkbox checked={allOrdersSelected()} />}
                            label={t('general.selectAll')}
                        />
                    )}
                    {orderList.map((order) => (
                        <OrderItemContainer
                            customerView={customerView}
                            key={order.id}
                            orders={[order]}
                            removeOrder={removeOrder}
                            saveOrder={saveOrder}
                            saveOrderShallow={saveOrderShallow}
                            //location={locationsById[order.location.id]}
                            updateOrderFormState={updateOrderFormState}
                            saveFilterOrder={saveFilterOrder}
                            //combineOrders={combineOrders}
                            allowPricing={allowPricing}
                            customers={customerDescriptions ? customerDescriptions : []}
                            customersById={customersById}
                            selected={selected[order.id]}
                            selectOrder={selectOrder}
                            makeCapacityReservations={onReserveCapacity}
                            editOrderPalletInformation={onEditOrderPalletInformation}
                            editFoPalletInformation={onEditFoPalletInformation}
                            parameters={props.parameters}
                            savingOrder={props.savingOrder}
                            addProductWithCode={addProductWithCode}
                            addProductWithId={addProductWithId}
                            removeCapacityReservations={onRemoveCapacityReservations}
                            updateCapacityReservationsIfNeeded={onUpdateCapacityReservationsIfNeeded}
                            combineOrders={combineOrders}
                        />
                    ))}
                    <br />
                    <br />

                    {combineOrders && (
                        <CombinedOrdersInput
                            {...combinedOrder}
                            customerView={customerView}
                            onOrderInfoBlur={onOrderInfoBlur}
                            onDateChange={onDateChangeCombinedOrder}
                            onEarlyDateChange={onEarlyDateChange}
                            inputError={inputError}
                            earliestDeliveryDate={earliestDeliveryDateForCombinedOrder}
                            deliveryDateDetails={deliveryDateDetailsCombinedOrder}
                            parameters={props.parameters}
                            deliveryMethod={getParametersObject('DeliveryMethod', combinedOrder.deliveryMethod)}
                        />
                    )}

                    <Footer>
                        <Grid item>
                            {orderList.length > 1 && ordersCanBeCombined() && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="combo-order-check"
                                            checked={combineOrders}
                                            onChange={onClickComboOrder}
                                            name="combo-order-check"
                                        />
                                    }
                                    label={t('order.merge')}
                                />
                            )}
                            {orderList.length > 1 && !ordersCanBeCombined() && (
                                <Tooltip title={t('order.ordersCannotBeMergedCheckData')}>
                                    <FormControlLabel
                                        disabled={true}
                                        control={
                                            <Checkbox
                                                id="combo-order-check"
                                                checked={combineOrders}
                                                onChange={onClickComboOrder}
                                                name="combo-order-check"
                                            />
                                        }
                                        label={t('order.merge')}
                                    />
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item>
                            {!anyFormsWithErrors && checkIfPrivateCustomerOrdersOk() && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    id="send-orders-btn"
                                    name="send-orders-btn"
                                    onClick={onOrderConfirmationMailInfo}
                                    startIcon={<PublishIcon />}>
                                    {t('buttons.send')}
                                </Button>
                            )}
                            {anyFormsWithErrors && (
                                <Tooltip title={t('order.ordersDoNotHaveAllMandatoryInformation')}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={true}
                                            name="send-orders-btn"
                                            startIcon={<PublishIcon />}>
                                            {t('buttons.send')}
                                        </Button>
                                    </div>
                                </Tooltip>
                            )}
                        </Grid>
                        {orderList.length > 1 && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    name="mass-edit-btn"
                                    onClick={onMassEdit}
                                    disabled={combineOrders || !anySamePortfolioOrderSelected()}
                                    startIcon={<EditIcon />}>
                                    {t('buttons.edit')}
                                </Button>
                            </Grid>
                        )}
                    </Footer>
                    <FooterPadding />

                    <OrderMassEditDialog
                        open={massEditOpen}
                        cancel={onCancelMassEdit}
                        save={onSaveMassEditData}
                        customerView={customerView}
                        parameters={props.parameters}
                        getEarliestDeliveryTime={getEarliestDeliveryTimeForMassEdit}
                        defaultValues={massEditDefaultValues}
                    />
                    <OrderConfirmationMailInputDialog
                        open={confirmationDialogOpen}
                        cancel={onConfirmationCancel}
                        save={onConfirmationAccept}
                        orders={selectedOrders()}
                        combineOrders={combineOrders}
                        userLanguage={userLanguage}
                    />
                </Grid>
            </Grid>

            <OrderSendStatusDialog
                open={sendingOrdersModalOpen}
                orderSendDone={isOrderSendDone()}
                orderSendFailed={orderSendFailed}
                okBtn={okBtn}
            />

            <PalletInfoSelectorDialog
                open={editOrderPalletInfo}
                cancel={cancelEditPalletInfo}
                save={savePalletInfo}
                allUsedCodes={usedPalletCodes}
                allowedPalletCodes={allowedPalletCodes}
                orderInScope={palletInfoEditOrder}
                fosInScope={palletInfoEditFos}
            />

            <ConfirmationDialog
                open={confirmDeliveryTimeResetOpen}
                confirm={confirmResetDeliveryTime}
                cancel={confirmResetDeliveryTime}
                confirmText={'OK'}
                hideCancel={true}>
                {t('order.notEnoughCapacityDueToChangeInFilters')}
            </ConfirmationDialog>
        </div>
    );
}
