class FilterInfo {
    constructor(json) {
        if (json != null) {
            this.filterType = json.filterType || '';
            this.filterClass = json.filterClass || '';
            this.filterEfficiency = json.filterEfficiency || 0;
            this.material = json.material || '';
            this.dimensionWidth = json.dimensionWidth || 0;
            this.dimensionHeight = json.dimensionHeight || 0;
            this.maxDepth = json.maxDepth || 0;
            this.count = json.count || 0;
            this.heatRecoveryModel = json.heatRecoveryModel || '';
        } else {
            this.filterType = '';
            this.filterClass = '';
            this.filterEfficiency = 0;
            this.material = '';
            this.dimensionWidth = 0;
            this.dimensionHeight = 0;
            this.maxDepth = 0;
            this.count = 0;
            this.heatRecoveryModel = '';
        }
    }
}

export default FilterInfo;
