import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import OrderTypeSelector from './ordertypeselector';
import DeliveryDatePicker from './deliveryDatePicker';
import TextField from '@material-ui/core/TextField';
import EmailInput from '../common/emailinput';
import ParameterSelector from '../common/parameterselector';
import Order from '../domain/order';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DeliveryDateDetails from './deliverydatedetails';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

const CombinedOrdersInput = ({
    customerView,
    onOrderInfoBlur,
    orderType,
    deliveryTime,
    onDateChange,
    inputError,
    deliveryTerms,
    ordererName,
    ordererTel,
    confirmationEmail,
    reference,
    generalInfo,
    packingInstructions,
    earliestDeliveryDate,
    deliveryMethod,
    parameters,
    onEarlyDateChange,
    earlyDeliveryTime,
    deliveryDateDetails,
}) => {
    const classes = useStyles();
    const [ignoreDateTimeChangeTimeoutHandle, setIgnoreDateTimeChangeTimeoutHandle] = React.useState(null);
    const { t } = useTranslation();

    const onOpenDeliveryDatePicker = () => {
        setIgnoreDateTimeChangeTimeoutHandle(
            setTimeout(() => {
                setIgnoreDateTimeChangeTimeoutHandle(null);
            }, 500)
        );
    };

    const onDateChangeInternal = (date) => {
        if (ignoreDateTimeChangeTimeoutHandle) {
            return;
        }
        onDateChange(date);
    };

    const onEarlyDateChangeInternal = (date) => {
        if (ignoreDateTimeChangeTimeoutHandle) {
            return;
        }
        onEarlyDateChange(date);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h4>{t('order.informationOnCombinedOrder')}</h4>
            </Grid>
            {!customerView && (
                <Grid item xs={12} sm={6}>
                    <OrderTypeSelector
                        handleChange={(value) => onOrderInfoBlur({ target: { value, name: 'OrderType' } })}
                        selected={orderType}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={6}>
                <DeliveryDateDetails deliveryDateDetails={deliveryDateDetails} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DeliveryDatePicker
                    label={t('general.deliveryDate') + ' *'}
                    value={deliveryTime}
                    onChange={onDateChangeInternal}
                    name={'combined-order-toimitusaika'}
                    earliestDeliveryDate={earliestDeliveryDate}
                    onOpen={onOpenDeliveryDatePicker}
                    deliveryMethod={deliveryMethod}
                />
            </Grid>
            {!customerView && orderType !== Order.OrderTypeUrgent() && (
                <Grid item xs={12} sm={6}>
                    <DeliveryDatePicker
                        label={t('order.earliestDeliveryDate') + ' *'}
                        value={earlyDeliveryTime}
                        onChange={onEarlyDateChangeInternal}
                        name={'combined-order-aikainentoimitus'}
                        earliestDeliveryDate={earliestDeliveryDate}
                        latestDeliveryDate={deliveryTime}
                        onOpen={onOpenDeliveryDatePicker}
                        disabled={!moment(deliveryTime).isValid()}
                        enableAllWeekDays={true}
                    />
                </Grid>
            )}
            <Grid item xs={12} sm={6}>
                <ParameterSelector
                    values={parameters}
                    category={'DeliveryMethod'}
                    selected={deliveryMethod}
                    handleChange={(type, value) => onOrderInfoBlur({ target: { value, name: 'DeliveryMethod' } })}
                    required={true}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    error={inputError['toimitusehdot']}
                    id="toimitusehdot"
                    name="combined-order-toimitusehdot"
                    label={t('parameterCategories.DeliveryTerms')}
                    className={classes.textField}
                    margin="normal"
                    defaultValue={deliveryTerms}
                    onBlur={onOrderInfoBlur}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    error={inputError['tilaaja-nimi']}
                    id="tilaaja-nimi"
                    name="combined-order-tilaaja-nimi"
                    label={t('order.ordererName')}
                    defaultValue={ordererName}
                    className={classes.textField}
                    margin="normal"
                    onBlur={onOrderInfoBlur}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    error={inputError['tilaaja-puh']}
                    id="tilaaja-puh"
                    name="combined-order-tilaaja-puh"
                    label={t('order.deliveryRecipientTel')}
                    defaultValue={ordererTel}
                    className={classes.textField}
                    margin="normal"
                    onBlur={onOrderInfoBlur}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <EmailInput
                    required={true}
                    error={inputError['confirmation-email']}
                    id="confirmation-email"
                    name="combined-order-confirmation-email"
                    onChange={(value) =>
                        onOrderInfoBlur({ target: { value, name: 'combined-order-confirmation-email' } })
                    }
                    emails={confirmationEmail}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    error={inputError['order-reference']}
                    id="order-reference"
                    name="combined-order-reference"
                    label={t('general.yourReference')}
                    defaultValue={reference}
                    className={classes.textField}
                    margin="normal"
                    onBlur={onOrderInfoBlur}
                />
            </Grid>
            {!customerView && (
                <Grid item xs={12} sm={6}>
                    <TextField
                        multiline={true}
                        id="order-packinginstructions"
                        name="combined-order-packinginstructions"
                        label={t('general.packingInstructions')}
                        defaultValue={!!packingInstructions ? packingInstructions : ''}
                        className={classes.textField}
                        margin="normal"
                        onBlur={onOrderInfoBlur}
                    />
                </Grid>
            )}
            {!customerView && (
                <Grid item xs={12} sm={6}>
                    <TextField
                        multiline={true}
                        id="order-general-info"
                        name="combined-order-general-info"
                        label={t('order.generalInfoToProduction')}
                        defaultValue={!!generalInfo ? generalInfo : ''}
                        className={classes.textField}
                        margin="normal"
                        onBlur={onOrderInfoBlur}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default CombinedOrdersInput;
