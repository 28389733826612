import moment from 'moment';
import ProductNote from './productnote';

class FilterProduct {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.storageId = json.storageId;
            this.productCode = json.productCode;
            this.productName = json.productName;
            this.type = json.type;
            this.fabricSurfaceArea = json.fabricSurfaceArea ? json.fabricSurfaceArea : 0;
            this.dimensionWidthMm = json.dimensionWidthMm;
            this.dimensionHeightMm = json.dimensionHeightMm;
            this.dimensionDepthMm = json.dimensionDepthMm;
            this.numberOfPockets = json.numberOfPockets;
            this.priceWithoutVat = json.priceWithoutVat ? json.priceWithoutVat : 0;
            this.toBeReplaced = json.toBeReplaced;
            this.productionPrice = json.productionPrice ? json.productionPrice : 0;
            this.expired = json.expired;
            this.status = json.status;
            this.added = moment(json.added);
            this.addedByUser = json.addedByUser;
            this.replacedByProductId = json.replacedByProductId;
            if (json.notes && json.notes.length > 0) {
                this.notes = json.notes.map((note) => new ProductNote(note));
            } else {
                this.notes = [];
            }
            this.material = json.material;
            this.filterClass = json.filterClass;
            this.classP = json.classP;
            this.frame = json.frame;
            this.gasket = json.gasket;
            this.materialType = json.materialType;
            this.productNameParsingSuccess = json.productNameParsingSuccess;
            this.legacyType = json.legacyType;
        } else {
            this.id = 0;
            this.storageId = 0;
            this.productCode = '';
            this.productName = '';
            this.type = '';
            this.fabricSurfaceArea = 0;
            this.dimensionWidthMm = 0;
            this.dimensionHeightMm = 0;
            this.dimensionDepthMm = 0;
            this.numberOfPockets = 0;
            this.priceWithoutVat = 0;
            this.toBeReplaced = false;
            this.productionPrice = 0;
            this.expired = false;
            this.status = 0;
            this.replacedByProductId = null;
            this.added = moment();
            this.addedByUser = undefined;
            this.notes = [];
            this.material = '';
            this.filterClass = '';
            this.classP = '';
            this.frame = '';
            this.gasket = '';
            this.materialType = '';
            this.productNameParsingSuccess = '';
        }
    }

    addNote(note) {
        this.notes.push(new ProductNote({ storageId: Math.random() * 10000, text: note, productId: this.id }));
    }

    setNoteText(text, storageId) {
        const noteToEditInd = this.notes.findIndex((n) => n.storageId === storageId);
        if (noteToEditInd !== -1) {
            if (text && text.length > 0) {
                this.notes[noteToEditInd].text = text;
            } else {
                this.notes.splice(noteToEditInd, 1);
            }
        }
    }

    static ParseProductFromEuroventName = (productName) => {
        try {
            //VPF NE ePM1 60% 592 592 525 8 New
            const items = productName.split(' ');
            const prod = new FilterProduct(null);
            prod.productName = productName;
            prod.type = items[0];
            prod.material = items[1];
            prod.filterClass = items[2];
            prod.classP = items[3];
            prod.dimensionWidthMm = parseInt(items[4]);
            prod.dimensionHeightMm = parseInt(items[5]);
            if (items[6].includes('/')) {
                prod.dimensionDepthMm = parseInt(items[6].split('/')[0]);
                prod.dimensionDepthMmS = parseInt(items[6].split('/')[1]);
            } else {
                prod.dimensionDepthMm = parseInt(items[6]);
            }

            if (items[7].includes('/')) {
                // sometimes it may have 5/200 etc. dunno what it means.
                prod.numberOfPockets = parseInt(items[7].split('/')[0]);
            } else {
                prod.numberOfPockets = parseInt(items[7]);
            }

            //((((f_height-20)+(f_width-25)/f_pockets)*0.001)*((f_depth-25)*0.001)*2)*f_pockets;

            prod.fabricSurfaceArea =
                (prod.dimensionHeightMm - 20 + Number((prod.dimensionWidthMm - 25) / prod.numberOfPockets)) *
                0.001 *
                ((prod.dimensionDepthMm - 25) * 0.001) *
                2 *
                prod.numberOfPockets;

            //Frame = productItems[9];
            //Gasket = productItems[10];
            //MaterialType = productItems[11];
            //ProductNameParsingSuccess = true;
            return prod;
        } catch (err) {
            return null;
        }
    };
    static StatusInUse = () => 0;
    static StatusDraftRequest = () => 1;
    static StatusImported = () => 2;

    productType() {
        return 'fp';
    }
}

export default FilterProduct;
