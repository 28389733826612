import React from 'react';
import dayjs from 'dayjs';
import Spinner from '../common/spinner';

const DeliveryDateDetails = ({ deliveryDateDetails }) => {
    if (!deliveryDateDetails) return <Spinner />;

    return (
        <React.Fragment>
            <h3>Toimitusajat</h3>
            {deliveryDateDetails.map((del) => (
                <div key={del.productType.id}>
                    {del.earliestDeliveryDate && (
                        <React.Fragment>
                            {dayjs(del.earliestDeliveryDate).format('DD.MM.YYYY')}&nbsp;-&nbsp;
                            {del.productType.name}
                        </React.Fragment>
                    )}
                    {!del.earliestDeliveryDate && (
                        <React.Fragment>
                            <strong>Ei kapasiteettia</strong>&nbsp;
                            {del.productType.name}
                        </React.Fragment>
                    )}
                </div>
            ))}
        </React.Fragment>
    );
};

export default DeliveryDateDetails;
