import i18next from 'i18next';

const msgDict = (errorcode, param) => {
    switch (errorcode) {
        case 'MT_Error10001':
            return i18next.t('errors.locationUsesExpiredProducts');
        case 'MT_Error10002':
            return i18next.t('errors.noMachinesInLocation');
        case 'MT_Error10003':
            return i18next.t('errors.noProductsInLocation');
        case 'MT_Error10004':
            return i18next.t('errors.nonContractPriceProductsInOrder');
        case 'MT_Error10011':
            return i18next.t('errors.invalidDeliveryAddress');
        case 'MT_Error10020':
            return i18next.t('errors.invalidInvoicingInfo');
        case 'MT_Error10021':
            return i18next.t('errors.locationContainsDraftProducts');
        case 'MT_Error10023':
            return i18next.t('errors.businessIdMissing');
        case 'MT_Error10024':
            return i18next.t('errors.productNotFoundExcelOrder', { productName: param });
        case 'MT_Error10025':
            return i18next.t('errors.noCapacityNeedsInOrder');
        case 'MT_Error10026':
            return i18next.t('errors.capacityReservationFailsTooLongPeriod');
        default:
            return i18next.t('general.error');
    }
};

export const ResolveErrorMessage = (errorcode) => {
    var parts = errorcode.split(';');
    return msgDict(parts[0], parts.length > 1 ? parts[1] : '');
};

export default ResolveErrorMessage;
