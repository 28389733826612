import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { getLocalizedParameterCategory } from '../common/localization';
import NativeSelect from '@material-ui/core/NativeSelect';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function ParameterSelector(props) {
    const classes = useStyles();
    const category = props.category;
    const values = props.values[props.category];
    const handleChange = props.handleChange;
    const selected = props.selected ? props.selected.id : 0;
    const showNoSelectionOption = !props.requireValue;
    const { t } = useTranslation();

    if (!props.values || props.values.length === 0) return null;

    //custom option erotus listan kärkeen
    const topOption = values.find((v) => v.id === 1);
    const optionValues = values.filter((v) => v.id !== 1);

    return (
        <FormControl className={classes.select}>
            <InputLabel id={category + '-label'}>{getLocalizedParameterCategory(category)}</InputLabel>
            <NativeSelect
                disabled={props.disabled}
                id={category}
                name={category}
                required={!!props.required}
                error={!!props.required && selected === 0}
                value={selected}
                onChange={(evt) => handleChange(category, parseInt(evt.target.value))}>
                {showNoSelectionOption && (
                    <option key={0} value={0}>
                        {t('general.optionNotSelected')}
                    </option>
                )}
                {topOption && (
                    <option key={topOption.id} value={topOption.id}>
                        {topOption.value}
                    </option>
                )}
                {optionValues.map((v) => (
                    <option key={v.id} value={v.id}>
                        {v.value}
                    </option>
                ))}
            </NativeSelect>
        </FormControl>
    );
}
