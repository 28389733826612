import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

export default function GlobalSpinner() {
    const orderSavingInProgress = useSelector((state) => state.orderdata.orderSavingInProgress);
    const customerOrderSavingInProgress = useSelector((state) => state.customerorderdata.orderSavingInProgress);
    const getOrderReportInProgress = useSelector((state) => state.reportdata.getOrderReportInProgress);
    const selectPortfolioInProgress = useSelector((state) => state.portfoliodata.isSelecting);
    const customerPortfolioUpdateInProgress = useSelector(
        (state) => state.customerdata.customerPortfolioUpdateInProgress
    );
    const isFetchingProdOrdersOpen = useSelector((state) => state.orderdata.isFetchingProdOrdersOpen);
    const isFetchingProdOrdersDelivered = useSelector((state) => state.orderdata.isFetchingProdOrdersDelivered);
    const isFetchingProdOrdersInvoiced = useSelector((state) => state.orderdata.isFetchingProdOrdersInvoiced);
    const productionOrderUpdateInProgress = useSelector((state) => state.orderdata.isUpdatingProdOrder);
    const getCustomerByIdInProgress = useSelector((state) => state.customerdata.getCustomerByIdInProgress);
    const savingInvoice = useSelector((state) => state.invoicedata.savingInvoice);
    const generatingInvoice = useSelector((state) => state.invoicedata.generatingInvoice);
    const savingCapacityReservations = useSelector((state) => state.prodplanningdata.savingCapacityReservations);
    const saveOrderShallowBatchInProgress = useSelector((state) => state.orderdata.saveOrderShallowBatchInProgress);
    const custSaveOrderShallowBatchInProgress = useSelector(
        (state) => state.customerorderdata.saveOrderShallowBatchInProgress
    );
    const contractPricesBulkSaveOngoing = useSelector((state) => state.productdata.contractPricesBulkSaveOngoing);
    const savingLocation = useSelector((state) => state.locationdata.savingData);
    const copyingLocationInProgress = useSelector((state) => state.locationdata.copyingLocationInProgress);
    const spinnerActive = useSelector((state) => state.appstate.spinnerActive);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (
            orderSavingInProgress ||
            customerOrderSavingInProgress ||
            getOrderReportInProgress ||
            selectPortfolioInProgress ||
            customerPortfolioUpdateInProgress ||
            isFetchingProdOrdersOpen ||
            isFetchingProdOrdersDelivered ||
            isFetchingProdOrdersInvoiced ||
            productionOrderUpdateInProgress ||
            getCustomerByIdInProgress ||
            savingInvoice ||
            generatingInvoice ||
            savingCapacityReservations ||
            saveOrderShallowBatchInProgress ||
            custSaveOrderShallowBatchInProgress ||
            contractPricesBulkSaveOngoing ||
            savingLocation ||
            copyingLocationInProgress ||
            spinnerActive
        ) {
            setShowSpinner(true);
        } else {
            setShowSpinner(false);
        }
    }, [
        orderSavingInProgress,
        customerOrderSavingInProgress,
        getOrderReportInProgress,
        selectPortfolioInProgress,
        customerPortfolioUpdateInProgress,
        isFetchingProdOrdersOpen,
        isFetchingProdOrdersDelivered,
        isFetchingProdOrdersInvoiced,
        productionOrderUpdateInProgress,
        getCustomerByIdInProgress,
        savingInvoice,
        generatingInvoice,
        savingCapacityReservations,
        saveOrderShallowBatchInProgress,
        custSaveOrderShallowBatchInProgress,
        contractPricesBulkSaveOngoing,
        savingLocation,
        copyingLocationInProgress,
        spinnerActive,
    ]);

    if (showSpinner) {
        return (
            <div>
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'lightgray',
                        opacity: '0.4',
                    }}></div>
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        margin: 'auto',
                        zIndex: '9999',
                        display: 'flex',
                        justifyContent: 'center',
                        top: '50%',
                    }}>
                    <CircularProgress color={'secondary'} size={'3rem'} />
                </div>
            </div>
        );
    }
    return null;
}
