import ParameterValue from './parametervalue';

class Contract {
    constructor(json) {
        if (json != null) {
            this.contractNumber = json.contractNumber ? new ParameterValue(json.contractNumber) : null;
            this.invoicingTerms = json.invoicingTerms ? new ParameterValue(json.invoicingTerms) : null;
            this.deliveryTerms = json.deliveryTerms ? new ParameterValue(json.deliveryTerms) : null;
        } else {
            this.contractNumber = null;
            this.invoicingTerms = null;
            this.deliveryTerms = null;
        }
    }
}

export default Contract;
