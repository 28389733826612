import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SurveyFilterInfo = ({ filterInfo, id, compact }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const tableContainerStyle = {
        width: !!compact ? 'auto' : '100%',
        margin: !!compact ? '0 auto' : 0,
    };

    const tableStyle = {
        width: !!compact ? 'auto' : '100%',
    };

    const tableCellStyle = {
        padding: theme.spacing(!!compact ? 0.5 : 1),
        whiteSpace: 'nowrap',
        size: !!compact ? 'small' : 'medium',
    };

    return (
        <TableContainer style={tableContainerStyle} component={Paper} id={`survey-filter-info-${id}`}>
            {isSmallScreen && (
                <Typography variant="subtitle1" align="center" gutterBottom style={{ fontWeight: 'bold' }}>
                    {t('survey.surveyReportFilterInfo')}
                </Typography>
            )}
            <Table style={tableStyle}>
                {!isSmallScreen && (
                    <TableHead>
                        <TableRow>
                            <TableCell style={tableCellStyle}>{t('survey.material')}</TableCell>
                            <TableCell style={tableCellStyle}>{t('survey.filterType')}</TableCell>
                            <TableCell style={tableCellStyle}>{t('survey.filterClass')}</TableCell>
                            <TableCell style={tableCellStyle}>{t('survey.filterEfficiency')}</TableCell>
                            <TableCell style={tableCellStyle}>{t('survey.width')}</TableCell>
                            <TableCell style={tableCellStyle}>{t('survey.height')}</TableCell>
                            <TableCell style={tableCellStyle}>{t('survey.maxDepth')}</TableCell>
                            <TableCell style={tableCellStyle}>{t('survey.quantity')}</TableCell>
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    <TableRow>
                        <TableCell style={tableCellStyle}>{filterInfo.material}</TableCell>
                        <TableCell style={tableCellStyle}>{filterInfo.filterType}</TableCell>
                        <TableCell style={tableCellStyle}>{filterInfo.filterClass}</TableCell>
                        <TableCell style={tableCellStyle}>{filterInfo.filterEfficiency}</TableCell>
                        <TableCell style={tableCellStyle}>{filterInfo.dimensionWidth}</TableCell>
                        <TableCell style={tableCellStyle}>{filterInfo.dimensionHeight}</TableCell>
                        <TableCell style={tableCellStyle}>{filterInfo.maxDepth}</TableCell>
                        <TableCell style={tableCellStyle}>{filterInfo.count}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

SurveyFilterInfo.propTypes = {
    filterInfo: PropTypes.object.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    compact: PropTypes.bool,
};

export default SurveyFilterInfo;
