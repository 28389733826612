import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import customerdata from './customerdata';
import appstate from './appstate';
import authentication from './authentication';
import actiondata from './actiondata';
import locationdata from './locationdata';
import productdata from './productdata';
import orderdata from './orderdata';
import customerlocationdata from './customerlocationdata';
import customerorderdata from './customerorderdata';
import feedbackdata from './feedbackdata';
import reportdata from './reportdata';
import customerreportdata from './customerreportdata';
import userdata from './userdata';
import portfoliodata from './portfoliodata';
import codedata from './codedata';
import searchdata from './searchdata';
import invoicedata from './invoicedata';
import parameterdata from './parameterdata';
import partydata from './partydata';
import duplicatedata from './duplicatedata';
import prodplanningdata from './prodplanningdata';
import settingsdata from './settingsdata';
import postaldata from './postaldata';
import surveydata from './surveydata';
import contractpricetequestdata from './contractpricerequestdata';
import contractdata from './contractdata';

const allReducers = (history) => {
    return combineReducers({
        router: connectRouter(history),
        customerdata,
        appstate,
        authentication,
        actiondata,
        locationdata,
        productdata,
        orderdata,
        customerlocationdata,
        customerorderdata,
        feedbackdata,
        reportdata,
        customerreportdata,
        userdata,
        portfoliodata,
        codedata,
        searchdata,
        invoicedata,
        parameterdata,
        partydata,
        duplicatedata,
        prodplanningdata,
        settingsdata,
        postaldata,
        surveydata,
        contractpricetequestdata,
        contractdata,
    });
};

export default allReducers;
