import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import * as reportActions from '../modules/reportdata';
import * as customerReportActions from '../modules/customerreportdata';
import * as orderActions from '../modules/orderdata';
import YearlyReport from './yearlyreport';
import CustomerYearlyReport from './customeryearlyreport';
import TabPanel from '../common/tabpanel';
import MonthlyReportView from './monthlyreport';
import InvoicingReports from './invoicingreports';
import RbacFragment from '../rbac/rbacfragment';
import User from '../domain/user';
import ProductionCapacityReport2 from './productioncapacityreport2';
import SalesReportExcel from './salesreportexcel';
import LostSales from './lostsales';
import i18next from 'i18next';
const _ = require('lodash');

function t(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            shouldRefreshYearlyReport: true,
            hideYearlyReport: false,
        };
        this.isCustomerSite = process.env.REACT_APP_TARGET === 'customerui';
    }

    componentDidUpdate() {
        // force-update the yearly graph if loaded history page first
        if (this.state.tab === 0 && this.state.shouldRefreshYearlyReport) {
            this.setState({ ...this.state, shouldRefreshYearlyReport: false });
        }
    }

    componentDidMount() {
        if (this.isCustomerSite) {
            if (this.props.yearlyReports == null) {
                this.props.getCustYearlyReports();
            }
            if (!_.includes(this.props.userProfile.roles, User.RoleAllowPricing())) {
                this.setState({ ...this.state, hideYearlyReport: true });
            }
        } else {
            let max_id = 0;
            this.props.getYearlyReports(max_id);
            this.props.getMonthlyReports(max_id);
            this.props.getSalesReport();
        }
    }

    handleChange = (event, value) => {
        this.setState({ ...this.state, tab: value });
        this.props.history.replace('/reports');
    };

    handleChangeIndex = (index) => {
        this.setState({ ...this.state, tab: index });
    };

    yearlyReportDom = (isActive) => {
        if (!isActive) return null;

        if (this.isCustomerSite) {
            return <CustomerYearlyReport reports={this.props.customerYearlyReports} />;
        } else {
            return <YearlyReport refresh={this.state.shouldRefreshYearlyReport} reports={this.props.yearlyReports} />;
        }
    };

    monthlyReportDom = (isActive) => {
        if (!isActive) return null;

        return <MonthlyReportView reports={this.props.monthlyReports} userProfile={this.props.userProfile} />;
    };

    render() {
        if (!this.props.userProfile) return null;
        return (
            <div>
                <AppBar position="static" color="default">
                    <Box display="flex" justifyContent="center" width="100%">
                        <Tabs
                            value={this.state.tab}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto">
                            {!this.state.hideYearlyReport && <Tab value={0} label={t('reports.year')} />}
                            {!this.isCustomerSite && <Tab value={1} label={t('reports.month')} />}
                            {!this.isCustomerSite && <Tab value={2} label={t('reports.salesReport')} />}
                            {!this.isCustomerSite && <Tab value={3} label={t('menu.production')} />}
                            {!this.isCustomerSite && (
                                <Tab
                                    value={4}
                                    label={t('general.invoicing')}
                                    disabled={
                                        this.props.userProfile.roles.findIndex((r) => r === User.RoleReporting()) === -1
                                    }
                                />
                            )}
                            {!this.isCustomerSite && <Tab value={5} label={t('general.lostSales')} />}
                        </Tabs>
                    </Box>
                </AppBar>
                <TabPanel value={this.state.tab} index={0}>
                    {this.yearlyReportDom(this.state.tab === 0)}
                </TabPanel>
                <TabPanel value={this.state.tab} index={1}>
                    {this.monthlyReportDom(this.state.tab === 1)}
                </TabPanel>
                <TabPanel value={this.state.tab} index={2}>
                    <SalesReportExcel active={this.state.tab === 2} />
                </TabPanel>
                <TabPanel value={this.state.tab} index={3}>
                    <ProductionCapacityReport2 active={this.state.tab === 3} />
                </TabPanel>
                <RbacFragment user={this.props.userProfile} requiredRole={User.RoleReporting()}>
                    <TabPanel value={this.state.tab} index={4}>
                        <InvoicingReports active={this.state.tab === 4} />
                    </TabPanel>
                </RbacFragment>
                <TabPanel value={this.state.tab} index={5}>
                    <LostSales active={this.state.tab === 5} />
                </TabPanel>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    customerYearlyReports: state.customerreportdata.yearlyReports,
    yearlyReports: state.reportdata.yearlyReports,
    gettingYearlyReports: state.reportdata.getYearlyReportInProgress,
    orderDataById: state.orderdata.orderDataById,
    monthlyReports: state.reportdata.monthlyReports,
    salesReport: state.reportdata.salesReport,
    userProfile: state.authentication.userProfile,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ ...reportActions, ...orderActions, ...customerReportActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
