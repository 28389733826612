import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CustomerIcon from '@material-ui/icons/Person';
import LocationIcon from '@material-ui/icons/StoreMallDirectory';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Feedback from '@material-ui/icons/Feedback';
import VadoLogo from './vadologo';
import ReportIcon from '@material-ui/icons/BarChart';
import ListIcon from '@material-ui/icons/List';
import HistoryIcon from '@material-ui/icons/History';
import packageInfo from '../../package.json';
import { rbacmenuitems } from '../rbac/rbacrules';
import RbacMenuItemAuth from '../rbac/rbacmenuitemauth';
import BuildIcon from '@material-ui/icons/Build';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { selectPortfolio } from '../modules/portfoliodata';
import { setShowcaseMode } from '../modules/appstate';
import SearchField from './search';
import RbacFragment from '../rbac/rbacfragment';
import User from '../domain/user';
import CONFIG from '../config';
import languages from '../i18n/languages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuItemSelected: {
        fontWeight: 900,
    },
    mobileMenu: {
        zIndex: 20,
    },
    popperMenu: {
        zIndex: 1500,
    },
}));

function MenuBar(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorPortfolioEl, setAnchorPortfolioEl] = React.useState(null);
    const [anchorLanguageEl, setAnchorLanguageEl] = React.useState(null);
    const [anchorShowcaseModeEl, setAnchorShowcaseModeEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [selectedView, setSelectedView] = React.useState('');
    const showcaseMode = useSelector((state) => state.appstate.showcaseMode);
    const { t, i18n } = useTranslation();
    const isMenuOpen = Boolean(anchorEl);
    const isPortfolioMenuOpen = Boolean(anchorPortfolioEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isLanguageSelectOpen = Boolean(anchorLanguageEl);
    const isShowcaseModeSelectOpen = Boolean(anchorShowcaseModeEl);

    const selectedLang = i18n.language;

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePortfolioMenuOpen = (event) => {
        setAnchorPortfolioEl(event.currentTarget);
    };

    const handleLanguageMenuOpen = (event) => {
        setAnchorLanguageEl(event.currentTarget);
    };

    const handleShowcaseModeMenuOpen = (event) => {
        setAnchorShowcaseModeEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setAnchorLanguageEl(null);
        setAnchorPortfolioEl(null);
        handleMobileMenuClose();
    };

    const handlePortfolioMenuClose = () => {
        setAnchorPortfolioEl(null);
    };

    const handleLanguageMenuClose = () => {
        setAnchorLanguageEl(null);
    };

    const handleShowcaseModeMenuClose = () => {
        setAnchorShowcaseModeEl(null);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const onClickLocations = () => {
        history.push('/locations');
        setSelectedView('locations');
        handleMobileMenuClose();
    };

    const onClickOrderHistory = () => {
        //TODO: update correct path and view
        history.push('/orderhistory');
        setSelectedView('orderhistory');
        handleMobileMenuClose();
    };

    const onClickHome = () => {
        history.push('/');
        setSelectedView('home');
        handleMobileMenuClose();
    };

    const onClickCustomers = () => {
        history.push('/customers');
        setSelectedView('customers');
        handleMobileMenuClose();
    };

    const onClickShoppingCart = () => {
        history.push('/basket');
        setSelectedView('basket');
        handleMobileMenuClose();
    };

    const onClickReports = () => {
        history.push('/reports');
        setSelectedView('reports');
        handleMobileMenuClose();
    };

    const onClickProducts = () => {
        history.push('/products');
        setSelectedView('products');
        handleMobileMenuClose();
    };

    const onClickProduction = () => {
        history.push('/production');
        setSelectedView('production');
        handleMobileMenuClose();
    };

    const onClickInvoicing = () => {
        history.push('/invoices');
        setSelectedView('invoices');
        handleMobileMenuClose();
    };

    const onClickAdmin = () => {
        history.push('/admin');
        setSelectedView('admin');
        handleMobileMenuClose();
    };

    const handleLogout = () => {
        history.push('/logout');
        handleMenuClose();
    };

    const openMobileMenu = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const getMenuButtonColor = (view) => {
        if (selectedView === view) return 'secondary';
        if (props.location.pathname.indexOf(view) !== -1) return 'secondary';
        return 'inherit';
    };

    const handleFeedbackOpen = () => {
        history.push('/feedback');
    };

    const handleUserDataOpen = () => {
        history.push('/user');
        handleMenuClose();
    };

    const openDebugData = () => {
        history.push('/debug');
        handleMenuClose();
    };

    const changeLanguage = (newLang) => {
        document.cookie = `lang=${newLang};max-age=31536000;path=/`;
        i18n.changeLanguage(newLang);
    };

    const renderProfileMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}>
            <MenuItem>
                {t('menu.version')} {packageInfo.version}
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
                {props.profile.name}
                <br />
                {props.profile.organization}
            </MenuItem>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.UserProfile}>
                <MenuItem onClick={handleUserDataOpen}>{t('menu.ownInfo')}</MenuItem>
            </RbacMenuItemAuth>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Portfolio}>
                <MenuItem onClick={handlePortfolioMenuOpen}>{t('general.portfolio')}</MenuItem>
            </RbacMenuItemAuth>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.ShowcaseMode}>
                <MenuItem onClick={handleShowcaseModeMenuOpen}>{t('menu.showcaseMode')}</MenuItem>
            </RbacMenuItemAuth>

            <MenuItem onClick={handleLanguageMenuOpen}>{t('general.language')}</MenuItem>

            <MenuItem onClick={handleLogout}>{t('menu.logout')}</MenuItem>

            {CONFIG.features.debugUi && <MenuItem onClick={openDebugData}>Debug</MenuItem>}
        </Menu>
    );

    const renderLanguageSelect = (
        <Popper className={classes.popperMenu} open={isLanguageSelectOpen} anchorEl={anchorLanguageEl} transition>
            <Grow in={true}>
                <Paper>
                    <ClickAwayListener onClickAway={handleLanguageMenuClose}>
                        <MenuList>
                            {languages.map((lang) => (
                                <MenuItem
                                    key={lang.lang}
                                    className={selectedLang === lang.lang ? classes.menuItemSelected : null}
                                    onClick={() => {
                                        changeLanguage(lang.lang);
                                        handleLanguageMenuClose();
                                    }}>
                                    <span style={{ margin: '5px 5px 5px 0px' }}>{lang.flagImg}</span>
                                    {t(lang.name)}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        </Popper>
    );

    const renderPortfolioMenu = (
        <Popper className={classes.popperMenu} open={isPortfolioMenuOpen} anchorEl={anchorPortfolioEl} transition>
            <Grow in={true}>
                <Paper>
                    <ClickAwayListener onClickAway={handlePortfolioMenuClose}>
                        <MenuList>
                            {props.profile.portfolioDescriptions.map((p) => (
                                <MenuItem
                                    key={p.id}
                                    className={
                                        props.profile.selectedPortfolioId === p.id ? classes.menuItemSelected : null
                                    }
                                    onClick={() => selectPortfolio(p.id)(dispatch)}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        </Popper>
    );

    const renderShowcaseModeMenu = (
        <Popper
            className={classes.popperMenu}
            open={isShowcaseModeSelectOpen}
            anchorEl={anchorShowcaseModeEl}
            transition>
            <Grow in={true}>
                <Paper>
                    <ClickAwayListener onClickAway={handleShowcaseModeMenuClose}>
                        <MenuList>
                            <MenuItem
                                key={'demoOff'}
                                className={showcaseMode ? null : classes.menuItemSelected}
                                onClick={() => setShowcaseMode(false)(dispatch)}>
                                {'Pois'}
                            </MenuItem>
                            <MenuItem
                                key={'demoOn'}
                                className={showcaseMode ? classes.menuItemSelected : null}
                                onClick={() => setShowcaseMode(true)(dispatch)}>
                                {'Päällä'}
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        </Popper>
    );

    const renderMobileMenu = (
        <Menu
            className={classes.mobileMenu}
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMenuClose}>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Home}>
                <MenuItem onClick={onClickHome}>
                    <IconButton color="inherit">
                        <HomeIcon />
                    </IconButton>
                    {t('menu.frontpage')}
                </MenuItem>
            </RbacMenuItemAuth>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Customers}>
                <MenuItem onClick={onClickCustomers}>
                    <IconButton color="inherit">
                        <CustomerIcon />
                    </IconButton>
                    {t('general.customers')}
                </MenuItem>
            </RbacMenuItemAuth>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Locations}>
                <MenuItem onClick={onClickLocations}>
                    <IconButton color="inherit">
                        <LocationIcon />
                    </IconButton>
                    {t('general.locations')}
                </MenuItem>
            </RbacMenuItemAuth>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.OrderHistory}>
                <MenuItem onClick={onClickOrderHistory}>
                    <IconButton color="inherit">
                        <HistoryIcon />
                    </IconButton>
                    {t('location.orderHistory')}
                </MenuItem>
            </RbacMenuItemAuth>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Reports}>
                <MenuItem onClick={onClickReports}>
                    <IconButton color="inherit">
                        <ReportIcon />
                    </IconButton>
                    {t('menu.reports')}
                </MenuItem>
            </RbacMenuItemAuth>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Products}>
                <MenuItem onClick={onClickProducts}>
                    <IconButton color="inherit">
                        <ListIcon />
                    </IconButton>
                    {t('general.products')}
                </MenuItem>
            </RbacMenuItemAuth>

            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Production}>
                <MenuItem onClick={onClickProduction}>
                    <IconButton color="inherit">
                        <i className="fas fa-industry"></i>
                    </IconButton>
                    {t('menu.production')}
                </MenuItem>
            </RbacMenuItemAuth>
            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Invoices}>
                <MenuItem onClick={onClickInvoicing}>
                    <IconButton color="inherit">
                        <i className="fas fa-file-invoice-dollar"></i>
                    </IconButton>
                    {t('menu.invoicing')}
                </MenuItem>
            </RbacMenuItemAuth>

            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.ShoppingCart}>
                <MenuItem onClick={onClickShoppingCart}>
                    <IconButton color="inherit">
                        {props.shoppingCartContent != null && (
                            <Badge badgeContent={props.shoppingCartContent} color="secondary" overlap="rectangular">
                                <ShoppingCart />
                            </Badge>
                        )}
                        {props.shoppingCartContent == null && <ShoppingCart />}
                    </IconButton>
                    {t('menu.orders')}
                </MenuItem>
            </RbacMenuItemAuth>

            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Feedback}>
                <MenuItem onClick={handleFeedbackOpen}>
                    <IconButton>
                        <Feedback style={{ color: 'black' }} />
                    </IconButton>
                    {t('general.feedback')}
                </MenuItem>
            </RbacMenuItemAuth>

            <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Admin}>
                <MenuItem onClick={onClickAdmin}>
                    <IconButton color="inherit">
                        <BuildIcon />
                    </IconButton>
                    {t('menu.maintenance')}
                </MenuItem>
            </RbacMenuItemAuth>
        </Menu>
    );

    if (props.location.search === '?sidebar=false') return null;

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={openMobileMenu}>
                            <MenuIcon />
                        </IconButton>
                    </div>

                    <div className={classes.sectionDesktop}>
                        <VadoLogo />
                    </div>

                    <div className={classes.sectionDesktop}>
                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Home}>
                            <Tooltip title={t('menu.frontpage')}>
                                <IconButton
                                    color={getMenuButtonColor('home')}
                                    aria-label="etusivu"
                                    onClick={onClickHome}>
                                    <HomeIcon />
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>

                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Customers}>
                            <Tooltip title={t('general.customers')}>
                                <IconButton
                                    color={getMenuButtonColor('customers')}
                                    aria-label="Asiakkaat"
                                    name="asiakkaat"
                                    onClick={onClickCustomers}>
                                    <CustomerIcon />
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>

                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Locations}>
                            <Tooltip title={t('general.locations')}>
                                <IconButton
                                    color={getMenuButtonColor('locations')}
                                    aria-label="Kohteet"
                                    onClick={onClickLocations}>
                                    <LocationIcon />
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>

                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.OrderHistory}>
                            <Tooltip title={t('location.orderHistory')}>
                                <IconButton
                                    color={getMenuButtonColor('orderhistory')}
                                    aria-label="Kohteet"
                                    onClick={onClickOrderHistory}>
                                    <HistoryIcon />
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>

                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Reports}>
                            <Tooltip title={t('menu.reports')}>
                                <IconButton
                                    color={getMenuButtonColor('reports')}
                                    aria-label="Raportit"
                                    onClick={onClickReports}>
                                    <ReportIcon />
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>

                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Products}>
                            <Tooltip title={t('general.products')}>
                                <IconButton
                                    color={getMenuButtonColor('products')}
                                    aria-label="Tuotteet"
                                    onClick={onClickProducts}>
                                    <ListIcon />
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>

                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Production}>
                            <Tooltip title={t('menu.production')}>
                                <IconButton
                                    color={getMenuButtonColor('production')}
                                    aria-label="Tuotanto"
                                    onClick={onClickProduction}>
                                    <i className="fas fa-industry"></i>
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>

                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Invoices}>
                            <Tooltip title={t('menu.invoicing')}>
                                <IconButton
                                    color={getMenuButtonColor('invoices')}
                                    aria-label="Laskutus"
                                    onClick={onClickInvoicing}>
                                    <i className="fas fa-file-invoice-dollar"></i>
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>

                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Admin}>
                            <Tooltip title={t('menu.maintenance')}>
                                <IconButton
                                    color={getMenuButtonColor('admin')}
                                    aria-label="Ylläpito"
                                    onClick={onClickAdmin}>
                                    <BuildIcon />
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>
                    </div>

                    <RbacFragment user={props.profile} requiredRole={User.RoleUser()}>
                        <SearchField history={props.history} />
                    </RbacFragment>

                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.ShoppingCart}>
                            <Tooltip title={t('menu.openOrders')}>
                                <IconButton color="inherit" onClick={onClickShoppingCart}>
                                    {props.shoppingCartContent != null && (
                                        <Badge badgeContent={props.shoppingCartContent} color="secondary" overlap="rectangular">
                                            <ShoppingCart />
                                        </Badge>
                                    )}
                                    {props.shoppingCartContent == null && <ShoppingCart />}
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>

                        <RbacMenuItemAuth user={props.profile} menuitem={rbacmenuitems.Feedback}>
                            <Tooltip title={t('general.giveFeedback')}>
                                <IconButton onClick={handleFeedbackOpen}>
                                    <Feedback style={{ color: 'black' }} />
                                </IconButton>
                            </Tooltip>
                        </RbacMenuItemAuth>
                    </div>

                    <Tooltip title={t('general.profile')}>
                        <IconButton
                            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit">
                            <AccountCircle />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            {renderProfileMenu}
            {renderPortfolioMenu}
            {renderLanguageSelect}
            {renderShowcaseModeMenu}
            {renderMobileMenu}
        </div>
    );
}

export default MenuBar;
