import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveLocation, removeLocation, getLocationFull, createLocationFromSurvey } from '../modules/locationdata';
import { getCustomerDescriptiveInfo } from '../modules/customerdata';
import { getCustomerFull } from '../modules/customerdata';
import { getFilterProducts } from '../modules/productdata';
import { getContractPrices, saveContractPricesInLocation } from '../modules/productdata';
import { getDuplicates } from '../modules/duplicatedata';
import LocationEditView from './locationeditview';
import Spinner from '../common/spinner';
import { DataRemoveStatus } from '../modules/locationdata';
import { getBillingInformation } from '../modules/locationdata';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function LocationEditViewContainer(props) {
    const id = props.match.params.locationId !== 'new' ? parseInt(props.match.params.locationId, 10) : 'new';
    const dispatch = useDispatch();
    const locationsById = useSelector((state) => state.locationdata.locationsById);
    const getLocByIdInProgress = useSelector((state) => state.locationdata.getLocByIdInProgress);
    const getLocByIdFailed = useSelector((state) => state.locationdata.getLocByIdFailed);
    const saveLocationFail = useSelector((state) => state.locationdata.saveDataFailed);
    const saveLocationOnProgress = useSelector((state) => state.locationdata.savingData);
    const saveLocationDone = useSelector((state) => state.locationdata.saveDone);
    const customerDescriptions = useSelector((state) => state.customerdata.customerDescriptions);
    const getCustomerDescriptionsInProgress = useSelector(
        (state) => state.customerdata.getCustomerDescriptionsInProgress
    );
    const customersById = useSelector((state) => state.customerdata.customersById);
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const filterProducts = useSelector((state) => state.productdata.filterProducts);
    const productBundles = useSelector((state) => state.productdata.productBundles);
    const saveActionDone = useSelector((state) => state.actiondata.isInsertDone);
    const removeLocationStatus = useSelector((state) => state.locationdata.removeLocationStatus);
    const saveFailReason = useSelector((state) => state.locationdata.saveFailReason);
    const parameters = useSelector((state) => state.parameterdata.parameters);
    const contracts = useSelector((state) => state.contractdata.contractsById);
    const contractPrices = useSelector((state) => state.productdata.contractPricesByContractId);
    const duplicates = useSelector((state) => state.duplicatedata.duplicates);
    const showcaseMode = useSelector((state) => state.appstate.showcaseMode);
    const { t } = useTranslation();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const surveyId = queryParams.get('surveyId');

    useEffect(() => {
        if (
            id !== 'new' &&
            locationsById[id] == null &&
            !getLocByIdInProgress &&
            !(removeLocationStatus[id] === DataRemoveStatus.Removing) &&
            !getLocByIdFailed
        ) {
            dispatch(getLocationFull(id));
        } else if (
            id === 'new' &&
            surveyId !== null &&
            locationsById[surveyId] == null &&
            !getLocByIdInProgress &&
            !(removeLocationStatus[id] === DataRemoveStatus.Removing) &&
            !getLocByIdFailed
        ) {
            dispatch(createLocationFromSurvey(surveyId));
        }
    }, [id, dispatch, getLocByIdFailed, getLocByIdInProgress, locationsById, removeLocationStatus, surveyId]);

    useEffect(() => {
        if (filterProducts === null) dispatch(getFilterProducts());
    }, [dispatch, filterProducts]);

    useEffect(() => {
        if (customerDescriptions === null && !getCustomerDescriptionsInProgress) dispatch(getCustomerDescriptiveInfo());
    }, [dispatch, customerDescriptions, getCustomerDescriptionsInProgress]);

    const getCustomerById = (id) => {
        dispatch(getCustomerFull(id));
    };

    const checkDuplicates = (id, locId, name, businessId) => {
        dispatch(getDuplicates(id, locId, name, businessId));
    };

    const locationSave = (location) => {
        return dispatch(saveLocation(location));
    };

    const locationRemove = (location) => {
        dispatch(removeLocation(location));
    };

    const doGetContractPrices = (value) => {
        return dispatch(getContractPrices(value));
    };

    const doSaveContractPricesInLocation = (prices) => {
        return saveContractPricesInLocation(prices);
    };

    const doGetBillingInformation = (location) => {
        return getBillingInformation(location);
    };

    if (getLocByIdInProgress) {
        return <Spinner padding="20px" title={t('general.loading')} />;
    } else if (!getLocByIdInProgress && getLocByIdFailed) {
        return (
            <div style={{ padding: '20px' }}>
                <Alert severity="error">{t('general.fetchFailedRetry')}</Alert>
            </div>
        );
    }

    return (
        <LocationEditView
            history={props.history}
            userProfile={userProfile}
            location={surveyId == null ? locationsById[id] : locationsById[surveyId]}
            saveLocationOnProgress={saveLocationOnProgress}
            saveLocationDone={saveLocationDone}
            saveLocationFail={saveLocationFail}
            saveLocation={locationSave}
            removeLocation={locationRemove}
            newLocation={id === 'new'}
            fromSurvey={surveyId != null}
            saveActionDone={saveActionDone}
            customers={customerDescriptions}
            filterProducts={filterProducts}
            productBundles={productBundles}
            customersById={customersById}
            getCustomerById={getCustomerById}
            locationRemoveStatus={removeLocationStatus[id]}
            getBillingInformation={doGetBillingInformation}
            parameters={parameters}
            contracts={contracts}
            contractPrices={contractPrices}
            getContractPrices={doGetContractPrices}
            saveContractPricesInLocation={doSaveContractPricesInLocation}
            checkDuplicates={checkDuplicates}
            duplicates={duplicates}
            duplicateLocations={props.duplicateLocations}
            saveFailReason={saveFailReason}
            showcaseMode={showcaseMode}
        />
    );
}
