import Ajax from './ajax';
import ContractPriceRequest from '../domain/contractpricerequest';
import { groupBy, filter } from 'lodash';
import { CP_UPDATE_PROGRESS } from './productdata';

//TODO: filename saveContractPricesInLocation

const initialState = {
    pricingRequestsByContractNumberId: null,
    loadingPricingRequests: false,
};

export const FETCH_CONTRACT_PRICE_PRICING_REQUEST_DATA_REQUESTED = 'contractpricepricingrequest/FETCH';
export const FETCHED_CONTRACT_PRICE_PRICING_REQUEST_DATA = 'contractpricepricingrequest/FETCHED';
export const REMOVED_CONTRACT_PRICE_PRICING_REQUEST = 'contractpricepricingrequest/REMOVED';

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTRACT_PRICE_PRICING_REQUEST_DATA_REQUESTED:
            return {
                ...state,
                loadingPricingRequests: true,
            };

        case FETCHED_CONTRACT_PRICE_PRICING_REQUEST_DATA:
            const requests = action.requests.map((c) => new ContractPriceRequest(c));
            const pricingRequestsByContractNumberId = groupBy(requests, 'contractnumberId');
            return {
                ...state,
                pricingRequestsByContractNumberId: pricingRequestsByContractNumberId,
                loadingPricingRequests: false,
            };
        case REMOVED_CONTRACT_PRICE_PRICING_REQUEST:
            const pricingRequestForContractNumber = state.pricingRequestsByContractNumberId[action.contractNumberId];
            const newPricingRequestForContractNumber = filter(
                pricingRequestForContractNumber,
                (cp) => cp.id !== action.pricingRequestId
            );
            let newPricingRequests = state.pricingRequestsByContractNumberId;
            newPricingRequests[action.contractNumberId] = newPricingRequestForContractNumber;
            return {
                ...state,
                pricingRequestsByContractNumberId: newPricingRequests,
            };
        case CP_UPDATE_PROGRESS: {
            if (action.updateStatus && action.updateStatus.progressPercent === 100) {
                return {
                    ...state,
                    pricingRequestsByContractNumberId: null,
                };
            }
            return state;
        }
        default:
            return {
                ...state,
            };
    }
};

export const getContractPricePricingRequests = () => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_CONTRACT_PRICE_PRICING_REQUEST_DATA_REQUESTED,
        });
        return Ajax.get('api/contractprice/pricingrequest')
            .then(function (data) {
                dispatch({
                    type: FETCHED_CONTRACT_PRICE_PRICING_REQUEST_DATA,
                    requests: data.data,
                });
            })
            .catch(function (err) {
                //TODO: dispatch?
                console.log(err);
                return false;
            });
    };
};

export const removeContractPricePricingRequest = (pricingRequestId, contractNumberId) => {
    return async (dispatch) => {
        try {
            await Ajax.delete(`api/contractprice/pricingrequest/${pricingRequestId}`);
            dispatch({
                type: REMOVED_CONTRACT_PRICE_PRICING_REQUEST,
                contractNumberId: contractNumberId,
                pricingRequestId: pricingRequestId,
            });
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};
