import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Filter from '../domain/filter';
import Machine from '../domain/machine';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FilterProductSelectAutoComplete from './filterproductselectdialog';
import FilterProductChangeDialog from './filterproductchangedialog';
import { makeStyles } from '@material-ui/core/styles';
import { CalculateMaterialSpeed } from '../common/machinematerialspeed';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumericInputElement, { TypeOnChange } from '../common/numericinput';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatCurrency, parseDecimal } from '../common/common';
import ConfirmationDialog from '../common/confirmationdialog';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import { getContractPrices /*, markAsLegacyProduct, saveProductRequest*/ } from '../modules/productdata';
import FilterMargin from './filtermargin';
import FilterProductWarning from './filterproductwarning';
// import RefreshIcon from '@material-ui/icons/Refresh';
import ObjectIdGenerator from '../modules/objectidgenerator';
// import FilterProduct from '../domain/filterproduct';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import SurveyFilterInfo from './surveyfilterinfo';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    machineName: {
        marginLeft: '20px',
    },
    table: {
        width: '100%',
    },
    swapIconCol: {
        textAlign: 'center',
    },
    warning: {
        color: 'orange',
        verticalAlign: 'middle',
        display: 'inline-flex',
        fontSize: '0.9em',
    },
    bundleproduct: {
        fontSize: '0.75em',
    },
}));

const filterMatchesFilterInfo = (filter, filterInfo) => {
    if (!filter || !filter.product || !filterInfo){
        return false;
    }
    const efficiency = filter.product.classP ? parseFloat(filter.product.classP.replace('%', '')) : NaN;
    const normalize = (str) => str.replace(/[\s,._]/g, '').toLowerCase();
    return filter.product.material === filterInfo.material &&
        (filterInfo.filterType !== 'VPF' || filter.product.legacyType === filterInfo.filterType) &&
        (!isNaN(efficiency) && efficiency === filterInfo.filterEfficiency) &&
        normalize(filter.product.filterClass) === normalize(filterInfo.filterClass) &&
        filter.product.dimensionHeightMm === filterInfo.dimensionHeight &&
        filter.product.dimensionWidthMm === filterInfo.dimensionWidth &&
        filter.product.dimensionDepthMm <= filterInfo.maxDepth;
}

export const newProductSelected = async (
    selectedProduct,
    filterProducts,
    machine,
    machineChangedCb,
    getFilterDiscountCb,
    getFilterPriceCb,
    contractPrices,
    productBundles
) => {
    const newMachine = new Machine(machine);

    for (let f of newMachine.filters) {
        if (!selectedProduct.bundle && f.product && f.product.id === selectedProduct.id) {
            f.count += 1;
            machineChangedCb(newMachine);
            return;
        } else if (selectedProduct.bundle && f.bundle && f.bundle.id === selectedProduct.id) {
            f.count += 1;
            machineChangedCb(newMachine);
            return;
        }
    }

    let discount = getFilterDiscountCb(selectedProduct);
    let price = getFilterPriceCb(selectedProduct);
    const objid = await ObjectIdGenerator.newId();
    const filter = new Filter({
        id: objid,
        product: !selectedProduct.bundle ? filterProducts.find((p) => p.id === selectedProduct.id) : null,
        bundle: selectedProduct.bundle ? productBundles.find((b) => b.id === selectedProduct.id) : null,
        viewOrder: newMachine.filters.length + 1,
        count: 1,
    });

    if (filter.product && newMachine.filterInfos && newMachine.filterInfos.length > 0) {
        const filterInfo = newMachine.filterInfos.find((info) => filterMatchesFilterInfo(filter, info));
        if (filterInfo && filterInfo.count) {
            filter.count = filterInfo.count
        }
    }

    let contractPrice = null;
    if (contractPrices && contractPrices.length) {
        if (selectedProduct.bundle) {
            contractPrice = contractPrices.find((p) => p.bundle && p.bundle.clusterId === filter.bundle.clusterId);
        } else {
            contractPrice = contractPrices.find((p) => p.product && p.product.storageId === filter.product.storageId);
        }
    }

    if (contractPrice && (contractPrice.discount || contractPrice.price)) {
        filter.discount = contractPrice.discount;
        filter.price = contractPrice.price;
        filter.contractPricesInUse = true;
    } else {
        filter.discount = discount === null && price === null ? 0 : discount;
        filter.price = price;
    }
    newMachine.filters.push(filter);
    machineChangedCb(newMachine);
};

export default function LocationEditMachine(props) {
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [showAddFilterSearch, setShowAddFilterSearch] = useState(false);
    const [expand, setExpand] = useState(props.expand);
    const [openingNewFilterDialog, setOpeningNewFilterDialog] = useState(false);
    const [showReplaceFilterSearch, setShowReplaceFilterSearch] = useState(false);
    const [toReplaceFilter, setToReplaceFilter] = useState(null);
    const [productRequestHint, setProductRequestHint] = useState('');
    const [selectedFilterInfo, setSelectedFilterInfo] = useState([]);
    const contractPrices = props.contractId ? props.contractPrices[props.contractId] : null;
    const classes = useStyles();
    const dispatch = useDispatch();
    //const [myProducts, setMyProducts] = React.useState([]);
    const prevPropsExpand = React.useRef(props.expand);
    const { t } = useTranslation();

    /*useEffect(() => {
        if (props.filterProducts && props.filterProducts.length > 0) {
            const myProd = [];
            for (let f of props.machine.filters) {
                const prod = props.filterProducts.find((p) => p.id === f.product.id);
                if (prod) myProd.push(prod);
            }
            setMyProducts(myProd);
        }
    }, [props.filterProducts, props.machine.filters]);*/

    useEffect(() => {
        if (prevPropsExpand.current !== props.expand) {
            setExpand(props.expand);
            prevPropsExpand.current = props.expand;
        }
    }, [props.expand, expand]);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        switch (name) {
            case 'airvolume': {
                let newMachine = new Machine(props.machine);
                const newValue = value.toString().replace(',', '.');
                newMachine.airVolume = newValue;
                if (newValue !== '' && !/^\d+[.]?\d{0,4}$/.test(newValue)) {
                    return;
                }
                props.machineChanged(newMachine);
                break;
            }
            case 'machineLocation': {
                let newMachine = new Machine(props.machine);
                newMachine.machineLocation = value;
                props.machineChanged(newMachine);
                break;
            }
            case 'machineArea': {
                let newMachine = new Machine(props.machine);
                newMachine.machineArea = value;
                props.machineChanged(newMachine);
                break;
            }
            case 'additionalInfo': {
                let newMachine = new Machine(props.machine);
                newMachine.additionalInfo = value;
                props.machineChanged(newMachine);
                break;
            }
            case 'machineName': {
                let newMachine = new Machine(props.machine);
                newMachine.name = value;
                props.machineChanged(newMachine);
                break;
            }
            default:
                break;
        }
    };

    const filterCountChanged = (id, value) => {
        let newMachine = new Machine(props.machine);
        for (let filt of newMachine.filters) {
            if (filt.id === id) {
                if (value === 0) {
                    newMachine.filters.splice(newMachine.filters.indexOf(filt), 1);
                    fixViewOrdering(newMachine);
                } else {
                    filt.count = value;
                }
                props.machineChanged(newMachine);
            }
        }
    };

    const removeFilter = (id) => {
        let newMachine = new Machine(props.machine);
        for (let filt of newMachine.filters) {
            if (filt.id === id) {
                newMachine.filters.splice(newMachine.filters.indexOf(filt), 1);
                fixViewOrdering(newMachine);
                props.machineChanged(newMachine);
            }
        }
    };

    const fixViewOrdering = (machine) => {
        // Assuming filters are always in correct order in machine object
        let ind = 1;
        for (const f of machine.filters) {
            f.viewOrder = ind++;
        }
    };

    const onClickDeleteMachine = (e) => {
        setConfirmDeleteModalOpen(true);
    };

    const confirmDeleteMachine = () => {
        props.machineRemoved(props.machine);
        setConfirmDeleteModalOpen(false);
    };

    const cancelDeleteMachine = () => {
        setConfirmDeleteModalOpen(false);
    };

    const onClickNewFilter = async (filterInfo) => {
        setOpeningNewFilterDialog(true);
        if (props.contractId && !contractPrices) {
            await getContractPrices(props.contractId)(dispatch);
        }
        if (filterInfo) {
            setSelectedFilterInfo([filterInfo]);
        } else {
            setSelectedFilterInfo([]);
        }
        setShowAddFilterSearch(true);
        setOpeningNewFilterDialog(false);
    };

    const newFilterProductSelected = async (id, item) => {
        setShowAddFilterSearch(false);
        if (item) {
            await newProductSelected(
                item,
                props.filterProducts,
                props.machine,
                props.machineChanged,
                props.getFilterDiscount,
                props.getFilterPrice,
                contractPrices,
                props.productBundles
            );
        }
    };

    const handleDiscountChange = (id, e) => {
        let value = e.target.value.toString().replace(',', '.');
        let newMachine = new Machine(props.machine);
        for (let filt of newMachine.filters) {
            if (filt.id === id) {
                if (!/^-?\d*[.]?\d{0,4}$/.test(value)) {
                    return;
                } else if (!(value > 100)) {
                    props.setFilterDiscount(filt.id, value);
                }
            }
        }
    };

    const handlePriceChange = (id, e) => {
        let value = e.target.value.toString().replace(',', '.');
        let newMachine = new Machine(props.machine);
        for (let filt of newMachine.filters) {
            if (filt.id === id) {
                if (!/^\d*[.]?\d{0,2}$/.test(value)) {
                    return;
                } else if (value >= 0) {
                    props.setFilterPrice(filt.id, value);
                }
            }
        }
    };

    const handleFilterAdditionalInfoChange = (id, e) => {
        let value = e.target.value;
        let newMachine = new Machine(props.machine);
        for (let filt of newMachine.filters) {
            if (filt.id === id) {
                filt.additionalInfo = value;
                props.machineChanged(newMachine);
            }
        }
    };

    const sanitizeDiscountValue = (id) => {
        let newMachine = new Machine(props.machine);
        for (let filt of newMachine.filters) {
            if (filt.id === id) {
                props.setFilterDiscount(filt.id, parseDecimal(filt.discount));
            }
        }
    };

    const sanitizePriceValue = (id) => {
        let newMachine = new Machine(props.machine);
        for (let filt of newMachine.filters) {
            if (filt.id === id) {
                if (filt.price === '') {
                    props.setFilterPrice(filt.id, 0);
                }
            }
        }
    };

    const sanitizeAirVolumeValue = () => {
        let newMachine = new Machine(props.machine);
        if (props.machine.airVolume === '') {
            newMachine.airVolume = 0;
            props.machineChanged(newMachine);
        }
    };

    const toggleExpand = () => {
        setExpand(!expand);
    };

    const onClickSortUp = () => {
        props.sortUp(props.machine.id);
    };

    const onClickSortDown = () => {
        props.sortDown(props.machine.id);
    };

    let machine = props.machine;
    if ((machine.name == null || machine.name.length === 0) && !expand) setExpand(true);

    const materialSpeed = () => {
        return CalculateMaterialSpeed(machine.airVolume, machine.filters);
    };

    const sortUp = (id) => {
        const newMachine = new Machine(props.machine);
        for (const f of newMachine.filters) {
            if (f.id === id) {
                if (f.viewOrder === 1) return;
                else {
                    const prev = newMachine.filters[f.viewOrder - 2];
                    prev.viewOrder++;
                    f.viewOrder--;
                    props.machineChanged(newMachine);
                }
            }
        }
    };

    const onDiscountPriceSwap = (id) => {
        props.swapDiscountPrice(id);
    };

    /*const isMarkedToBeReplaced = (product) => {
        const prod = myProducts.find((p) => p.id === product.id);
        if (prod) {
            return prod.toBeReplaced;
        }
        return false;
    };

    const markToBeReplaced = async (product) => {
        if (!product.expired) {
            dispatch(markAsLegacyProduct(product));
        } else {
            // for expired products, consider "Uusi" button as creation of new product request
            // since we cannot set "tobereplaced" attribute for expired product.
            const id = await ObjectIdGenerator.newId();
            const prod = new FilterProduct();
            prod.productCode = '0';
            prod.id = id;
            prod.productName = product.productName;
            prod.status = FilterProduct.StatusDraftRequest();
            await saveProductRequest(prod)(dispatch);

            await props.replaceProduct(product.id, prod.id);
        }
    };*/

    const replaceFilterProductWithNewProduct = async (newProductId, replaceType) => {
        if (newProductId) {
            await props.replaceProduct(
                toReplaceFilter.product ? toReplaceFilter.product.id : toReplaceFilter.bundle.id,
                newProductId,
                toReplaceFilter.id,
                machine.id,
                replaceType
            );
        }
        setShowReplaceFilterSearch(false);
        setToReplaceFilter(null);
    };

    const filterInfoHasEquivalentFilter = (filterInfo) => {
        if (!machine || !machine.filters || machine.filters.length === 0){
            return false;
        }
        for (let filter of machine.filters) {
            if (filterMatchesFilterInfo(filter, filterInfo)) {
                return true;
            }
        }
        return false;
    }

    return (
        <Grid key={machine.id}>
            <Accordion expanded={expand}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleExpand} name="expand-machine">
                    <Grid container>
                        <Grid item xs={12}>
                            <h5 className={classes.machineName}>
                                {machine.name}
                                {machine.containsRemovedProducts() ? (
                                    <Tooltip title={t('location.hints.containsExpiredProducts')}>
                                        <WarningIcon className={classes.warning} name="warningIcon" />
                                    </Tooltip>
                                ) : machine.containsDraftProducts() ? (
                                    <Tooltip title={t('location.hints.containsDraftProducts')}>
                                        <WarningIcon className={classes.warning} name="warningIcon" />
                                    </Tooltip>
                                ) : null}
                                &nbsp;{machine.machineLocation}&nbsp;{machine.machineArea}
                            </h5>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Grid container spacing={2} justifyContent={'space-between'}>
                            <Grid item>
                                <TextField
                                    label={t('location.machineName')}
                                    value={machine.name}
                                    onChange={handleChange}
                                    name="machineName"
                                />
                            </Grid>
                            <Grid item>
                                <Button onClick={onClickSortUp} name="btn-machine-sort-up">
                                    <i className="fas fa-arrow-up fa-lg"></i>
                                </Button>
                                <Button onClick={onClickSortDown} name="btn-machine-sort-down">
                                    <i className="fas fa-arrow-down fa-lg"></i>
                                </Button>
                                <Button onClick={onClickDeleteMachine} name="btn-remove-machine">
                                    <i
                                        className="fas fa-trash-alt fa-lg"
                                        style={{ color: 'red' }}
                                        title={t('location.removeMachine')}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                    label={t('location.airVolume') + ' m/s'}
                                    onChange={handleChange}
                                    name="airvolume"
                                    value={machine.airVolume}
                                    onBlur={() => sanitizeAirVolumeValue()}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                    label={t('location.position')}
                                    onChange={handleChange}
                                    name="machineLocation"
                                    value={machine.machineLocation}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                    label={t('location.serviceArea')}
                                    onChange={handleChange}
                                    name="machineArea"
                                    value={machine.machineArea}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                    label={t('general.additionalInformation')}
                                    onChange={handleChange}
                                    name="additionalInfo"
                                    value={machine.additionalInfo}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    inputProps={{ readOnly: true }}
                                    label={t('location.materialSpeed') + ' m/s'}
                                    name="materialSpeed"
                                    value={materialSpeed()}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Table size="small" className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('general.product')}</TableCell>
                                            <TableCell>{t('pcs')}</TableCell>
                                            {!props.showcaseMode && (
                                                <TableCell>
                                                    {t('general.discount')} / {t('general.price')}
                                                </TableCell>
                                            )}
                                            <TableCell>{t('general.additionalInformation')}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {machine.filters.map((filter) => (
                                            <TableRow key={filter.id}>
                                                <TableCell>
                                                    <FilterProductWarning
                                                        product={filter.product}
                                                        bundle={filter.bundle}
                                                        locContractPriceInUse={props.contractId !== null}
                                                        contractPriceInUse={filter.contractPricesInUse}
                                                    />
                                                    {filter.product && (
                                                        <React.Fragment>
                                                            <span>{filter.product.productName}</span>
                                                            <br />
                                                            {filter.product.productCode !== '0' && (
                                                                <span>{filter.product.productCode}</span>
                                                            )}
                                                            <br />
                                                            {t('general.surfaceArea')}:&nbsp;
                                                            {filter.product.fabricSurfaceArea && (
                                                                <>{filter.product.fabricSurfaceArea} &#x33A1;</>
                                                            )}
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    {filter.bundle && (
                                                        <React.Fragment>
                                                            <span>{filter.bundle.name}</span> ({filter.bundle.code})
                                                            <br />
                                                            {filter.bundle.products.map((p) => (
                                                                <div className={classes.bundleproduct}>
                                                                    {p.productCount} x {p.product.productName}
                                                                </div>
                                                            ))}
                                                            {t('general.surfaceArea')}:&nbsp;
                                                            {filter.fabricSurfaceArea()} &#x33A1;
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    {!props.showcaseMode && (
                                                        <React.Fragment>
                                                            {t('general.rrp')}{' '}
                                                            {formatCurrency(filter.catalogValuePerPcs())}/{t('pcs')}
                                                            <br />
                                                            <FilterMargin filter={filter} />
                                                        </React.Fragment>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <NumericInputElement
                                                        id={filter.id}
                                                        value={filter.count}
                                                        onChange={filterCountChanged}
                                                        type={TypeOnChange}
                                                        min={1}
                                                    />
                                                </TableCell>
                                                {!props.showcaseMode && (
                                                    <TableCell>
                                                        {filter.discount !== null && !filter.contractPricesInUse && (
                                                            <Grid container>
                                                                <Grid item xs={12} md={5}>
                                                                    <TextField
                                                                        name="filterDiscount"
                                                                        value={filter.discountRounded()}
                                                                        onChange={(e) =>
                                                                            handleDiscountChange(filter.id, e)
                                                                        }
                                                                        onBlur={() => sanitizeDiscountValue(filter.id)}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    %
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={2}
                                                                    className={classes.swapIconCol}>
                                                                    <SwapHorizIcon
                                                                        name="swapIcon"
                                                                        onClick={() => onDiscountPriceSwap(filter.id)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={5}>
                                                                    <span>
                                                                        {formatCurrency(
                                                                            filter.calculateDiscountPricePerPcs()
                                                                        )}
                                                                        /{t('pcs')}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                        {filter.price !== null && !filter.contractPricesInUse && (
                                                            <Grid container>
                                                                <Grid item xs={12} md={5}>
                                                                    <span>{filter.calculateDiscountPercent()} %</span>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={2}
                                                                    className={classes.swapIconCol}>
                                                                    <SwapHorizIcon
                                                                        name="sawpIcon"
                                                                        onClick={() => onDiscountPriceSwap(filter.id)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={5}>
                                                                    <TextField
                                                                        name="filterPrice"
                                                                        value={filter.priceRounded()}
                                                                        onChange={(e) =>
                                                                            handlePriceChange(filter.id, e)
                                                                        }
                                                                        onBlur={() => sanitizePriceValue(filter.id)}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    €/{t('pcs')}
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                        {filter.contractPricesInUse && (
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Tooltip
                                                                        title={t(
                                                                            'location.contractPriceSetCannotEditHint'
                                                                        )}>
                                                                        <span name="contract-price">
                                                                            {t('general.contractPrice')}&nbsp;&nbsp;{' '}
                                                                            {filter.price !== null
                                                                                ? formatCurrency(filter.price)
                                                                                : formatCurrency(
                                                                                      filter.calculateDiscountPricePerPcs()
                                                                                  )}{' '}
                                                                            /{t('pcs')}
                                                                        </span>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </TableCell>
                                                )}
                                                <TableCell>
                                                    <TextField
                                                        name="filteradditionalinfo"
                                                        value={filter.additionalInfo}
                                                        onChange={(e) => handleFilterAdditionalInfoChange(filter.id, e)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Button onClick={() => sortUp(filter.id)} name="btn-filter-sort-up">
                                                        <i className="fas fa-arrow-up fa-lg"></i>
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="delete-filter"
                                                        name="delete-filter"
                                                        onClick={() => removeFilter(filter.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>

                                                    <Button
                                                        name="btn-change-product"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            setToReplaceFilter(filter);
                                                            setProductRequestHint(
                                                                filter.product
                                                                    ? filter.product.productName
                                                                    : filter.bundle.name
                                                            );
                                                            setShowReplaceFilterSearch(true);
                                                        }}
                                                        disabled={false}
                                                        startIcon={<SwapHorizIcon />}>
                                                        {t('buttons.switch')}
                                                    </Button>

                                                    {/*(filter.product.productCode !== '0' || filter.product.expired) && (
                                                        <Tooltip
                                                            title={
                                                                isMarkedToBeReplaced(filter.product)
                                                                    ? t('location.hints.productMarkedAsReplaced')
                                                                    : t('location.hints.productExpiredMarkToBeReplaced')
                                                            }>
                                                            <div className={classes.withPadding}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    name="btn-mark-to-be-replaced"
                                                                    onClick={() => markToBeReplaced(filter.product)}
                                                                    startIcon={<RefreshIcon />}
                                                                    disabled={isMarkedToBeReplaced(filter.product)}>
                                                                    {t('buttons.markToBeRenewed')}
                                                                </Button>
                                                            </div>
                                                        </Tooltip>
                                                        )*/}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <br />
                        </Grid>

                        {!showAddFilterSearch && (() => {
                            if (machine.filterInfos && machine.filterInfos.length > 0) {
                                const filterInfosWithEquivalent = machine.filterInfos.map(f => ({
                                    filterInfo: f,
                                    hasEquivalent: filterInfoHasEquivalentFilter(f)
                                }));
                                const hasNonEquivalentFilter = filterInfosWithEquivalent.some(f => !f.hasEquivalent);
                                if (hasNonEquivalentFilter){
                                    return filterInfosWithEquivalent
                                        .filter(f => !f.hasEquivalent)
                                        .map(({ filterInfo }, index) => (
                                            <Grid container key={'survey-filter-info-' + index} alignItems="center">
                                                <Grid item>
                                                    <Button
                                                        key={'btn-add-new-product-' + index}
                                                        name="btn-add-new-product"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={openingNewFilterDialog}
                                                        startIcon={<Add />}
                                                        onClick={() => onClickNewFilter(filterInfo)}>
                                                        {t('general.product')}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <SurveyFilterInfo filterInfo={filterInfo} id={machine.id + "-" + index} />
                                                </Grid>
                                            </Grid>
                                        ));
                                }
                            }
                            return (<Grid container>
                                <Grid item>
                                    <Button
                                        name="btn-add-new-product"
                                        variant="contained"
                                        color="primary"
                                        disabled={openingNewFilterDialog}
                                        startIcon={<Add />}
                                        onClick={() => onClickNewFilter(undefined)}>
                                        {t('general.product')}
                                    </Button>
                                </Grid>
                            </Grid>);
                        })()}

                        {showAddFilterSearch && (
                            <FilterProductSelectAutoComplete
                                filterInfos={selectedFilterInfo}
                                selected={newFilterProductSelected}
                                show={showAddFilterSearch}
                                allowNewProd={true}
                                showBundles={true}
                            />
                        )}
                        {showReplaceFilterSearch && (
                            <FilterProductChangeDialog
                                selected={replaceFilterProductWithNewProduct}
                                show={showReplaceFilterSearch}
                                allowNewProd={true}
                                productRequestHint={productRequestHint}
                            />
                        )}
                        <br />
                    </div>
                </AccordionDetails>
            </Accordion>

            <ConfirmationDialog
                open={confirmDeleteModalOpen}
                confirm={confirmDeleteMachine}
                cancel={cancelDeleteMachine}
                confirmText={'Poista'}>
                <span>{t('location.confirmRemoveMachine', { machineName: machine.name })}</span>
            </ConfirmationDialog>
        </Grid>
    );
}
