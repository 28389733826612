import Address from './address';
import Location from './location';
import ObjectIdGenerator from '../modules/objectidgenerator';
import ElectronicInvoiceInfo from './electronicinvoiceinfo';
import CustomershipStatus from './customershipstatus';
import Note from './note';
import { PortfolioDescription } from './portfolio';
import Logger from '../common/logger';
import ParameterValue from './parametervalue';
import TreeDescription from './treedescription';
import PartyRelation from './partyrelation';
import Contract from './contract';
const _ = require('lodash');

class Customer {
    constructor(jsonInput) {
        if (jsonInput != null) {
            try {
                this.id = jsonInput.id;
                this.name = jsonInput.name;
                this.businessId = jsonInput.businessId;
                this.contactName = jsonInput.contactName;
                this.contactTel = jsonInput.contactTel;
                this.customerNumber = jsonInput.customerNumber;
                if (jsonInput.electronicInvoiceAddress != null)
                    this.electronicInvoiceAddress = new ElectronicInvoiceInfo(jsonInput.electronicInvoiceAddress);
                else this.electronicInvoiceAddress = null;
                if (jsonInput.deliveryAddress != null) this.deliveryAddress = new Address(jsonInput.deliveryAddress);
                if (jsonInput.billingAddress != null) this.billingAddress = new Address(jsonInput.billingAddress);
                this.storageId = jsonInput.storageId;
                this.customerLocations = [];
                if (jsonInput.customerLocations != null) {
                    for (let loc of jsonInput.customerLocations) {
                        this.customerLocations.push(new Location(loc));
                    }
                }
                this.customerAsBillingCustomerLocations = [];
                if (jsonInput.customerAsBillingCustomerLocations != null) {
                    for (let loc of jsonInput.customerAsBillingCustomerLocations) {
                        this.customerAsBillingCustomerLocations.push(new Location(loc));
                    }
                }
                this.customerAsDeliveryCustomerLocations = [];
                if (jsonInput.customerAsDeliveryCustomerLocations != null) {
                    for (let loc of jsonInput.customerAsDeliveryCustomerLocations) {
                        this.customerAsDeliveryCustomerLocations.push(new Location(loc));
                    }
                }
                this.billingNameNotationSetting = jsonInput.billingNameNotationSetting;
                this.status = jsonInput.status;

                this.notes = [];
                if (jsonInput.notes && jsonInput.notes.length > 0) {
                    for (let n of jsonInput.notes) {
                        this.notes.push(new Note(n));
                    }
                    this.notes = _.sortBy(this.notes, function (item) {
                        return -item.noteSaved.valueOf();
                    });
                }
                this.portfolio = jsonInput.portfolio
                    ? new PortfolioDescription(jsonInput.portfolio)
                    : new PortfolioDescription();
                this.visibleToAll = jsonInput.visibleToAll;
                this.useCount = jsonInput.useCount;

                //this.billingTerms = jsonInput.billingTerms;
                this.invoicingTerms = jsonInput.invoicingTerms ? new ParameterValue(jsonInput.invoicingTerms) : null;
                this.deliveryTerms = jsonInput.deliveryTerms ? new ParameterValue(jsonInput.deliveryTerms) : null;
                this.deliveryMethod = jsonInput.deliveryMethod ? new ParameterValue(jsonInput.deliveryMethod) : null;
                this.contractNumber = jsonInput.contractNumber ? new ParameterValue(jsonInput.contractNumber) : null;
                this.ourReference = jsonInput.ourReference ? new ParameterValue(jsonInput.ourReference) : null;
                this.packingInstructions = jsonInput.packingInstructions;
                this.customershipValue = jsonInput.customershipValue;
                this.treeData = new TreeDescription(jsonInput.treeData);
                this.reference = jsonInput.reference;
                this.vatId = jsonInput.vatId;
                this.partyRelations = [];
                if (jsonInput.partyRelations && jsonInput.partyRelations.length > 0) {
                    for (const p of jsonInput.partyRelations) {
                        this.partyRelations.push(new PartyRelation(p));
                    }
                }
                this.emailInvoiceAddress = jsonInput.emailInvoiceAddress;
                this.contract = new Contract(jsonInput.contract);
            } catch (error) {
                Logger.LogError(error.message, error.stack);
                console.log(error);
            }
        } else {
            this.id = 0;
            this.name = '';
            this.deliveryAddress = null;
            this.billingAddress = null;
            this.contactName = '';
            this.contactTel = '';
            this.customerNumber = null;
            this.electronicInvoiceAddress = null;
            this.billingTerms = '';
            this.storageId = null;
            this.customerLocations = [];
            this.customerAsBillingCustomerLocations = [];
            this.customerAsDeliveryCustomerLocations = [];
            this.billingNameNotationSetting = Customer.BillingNameNotationDefault();
            this.status = CustomershipStatus.Active();
            this.notes = [];
            this.portfolio = null;
            this.visibleToAll = false;
            this.useCount = 0;
            this.emailInvoiceAddress = null;
        }
    }

    static async NewObject(portfolio) {
        if (!portfolio) throw new Error('virhe - ei salkkua mihin liittää uusi asiakas');
        const newCust = new Customer();
        newCust.portfolio = new PortfolioDescription(portfolio);
        try {
            const resps = await Promise.all([ObjectIdGenerator.newId(), ObjectIdGenerator.newCustomerNumber()]);
            newCust.id = resps[0];
            newCust.customerNumber = resps[1];
            return newCust;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    static BillingNameNotationDefault() {
        return 0;
    }
    //static CareOfNotationUseAlways() { return 1; } -> Deprecated, shall not be used anymore
    static BillingNameNotationUseCustomerName() {
        return 2;
    }
    static BillingNameNotationUseCustomerAndLocationName() {
        return 3;
    }
}

export default Customer;
