import Ajax from './ajax';
import Contract from '../domain/contract';
import { cloneDeep } from 'lodash';

const initialState = {
    contractsById: {},
    loadingContracts: false,
    updatingContracts: false,
};

// Action types
export const GET_CONTRACT_DATA_REQ = 'contractrequest/GETCONTRACTDATAREQ';
export const GET_CONTRACT_DATA_SUCCESS = 'contractrequest/GETCONTRACTDATASUCCESS';
export const GET_CONTRACT_DATA_BY_ID_REQ = 'contractrequest/GETCONTRACTDATABYIDREQ';
export const GET_CONTRACT_DATA_BY_ID_SUCCESS = 'contractrequest/GETCONTRACTDATABYIDSUCCESS';
export const UPDATE_CONTRACT_DATA_REQ = 'contractrequest/UPDATECONTRACTDATAREQ';
export const UPDATE_CONTRACT_DATA_SUCCESS = 'contractrequest/UPDATECONTRACTDATASUCCESS';
export const UPDATE_CONTRACT_DATA_FAIL = 'contractrequest/UPDATECONTRACTDATAFAIL';

// Action creators
export const contractDataUpdated = (contract) => ({
    type: UPDATE_CONTRACT_DATA_SUCCESS,
    contract: contract,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTRACT_DATA_REQ:
            return {
                ...state,
                loadingContracts: true,
            };
        case GET_CONTRACT_DATA_SUCCESS:
            const contracts = action.contracts.map((c) => new Contract(c));
            const contractsById = contracts.reduce((acc, contract) => {
                acc[contract.contractNumber.id] = contract;
                return acc;
            }, {});
            return {
                ...state,
                contractsById: contractsById,
                loadingContracts: false,
            };
        case GET_CONTRACT_DATA_BY_ID_REQ:
            return {
                ...state,
                loadingContracts: true,
            };
        case GET_CONTRACT_DATA_BY_ID_SUCCESS: {
            const newContractData = cloneDeep(state.contractsById);
            newContractData[action.contractNumberId] = action.contract;
            return {
                ...state,
                contractsById: newContractData,
                loadingContracts: false,
            };
        }
        case UPDATE_CONTRACT_DATA_REQ:
            return {
                ...state,
                updatingContracts: true,
            };
        case UPDATE_CONTRACT_DATA_SUCCESS:
            return {
                ...state,
                updatingContracts: false,
                contractsById: {
                    ...state.contractsById,
                    [action.contract.contractNumber.id]: new Contract(action.contract),
                },
            }
        case UPDATE_CONTRACT_DATA_FAIL:
            return {
                ...state,
                updatingContracts: false,
            };
        default:
            return {
                ...state,
            };
    }
};

export const getContractData = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_CONTRACT_DATA_REQ,
        });
        return Ajax.get('api/contract/')
            .then(function (data) {
                dispatch({
                    type: GET_CONTRACT_DATA_SUCCESS,
                    contracts: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                return false;
            });
    };
};

export const getContractDataById = (contractNumberId) => {
    return async (dispatch) => {
        dispatch({
            type: GET_CONTRACT_DATA_BY_ID_REQ,
        });
        return Ajax.get(`api/contract/${contractNumberId}`)
            .then(function (data) {
                dispatch({
                    type: GET_CONTRACT_DATA_BY_ID_SUCCESS,
                    contractNumberId: contractNumberId,
                    contract: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                return false;
            });
    };
};

export const updateContractData = (contract) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_CONTRACT_DATA_REQ,
        });
        
        return await Ajax.put(`api/contract/${contract.contractNumber.id}`, contract)
            .then(function (data) {
                dispatch(contractDataUpdated(data.data));
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: UPDATE_CONTRACT_DATA_FAIL
                });
                return false;
            });
    };
};
