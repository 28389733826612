import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

const DEFAULT_SELECTION = '-';

const useStyles = makeStyles((theme) => ({
    text: {
        padding: '5px',
        width: '20%',
    },
    selectedCompProd: {
        fontSize: '1.3em',
    },
    selectedCompProdHint: {
        fontSize: '0.85em',
        color: 'gray',
    },
}));

export default function MachineRow(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const machine = props.machine;
    const referenceDatasFromMachine = props.referenceDatasFromMachine;
    const updateCallBack = props.update;
    const vadoReferenceFilterFromEuroventData = props.referenceFilter;
    const vadoReferenceProductsInEuroventData = props.vadoReferenceProductsInEuroventData;
    const filteredCompetitorReferenceProducts = props.filteredCompetitorReferenceProducts;
    const [vadoReferenceProduct, setVadoReferenceProduct] = useState(null);
    const [competitorReferenceProduct, setCompetitorReferenceProduct] = useState(null);
    const [filterComparisonParameters, setFilterComparisons] = useState(null);

    useEffect(() => {
        if (machine && machine.filters && machine.filters.length > 0) {
            // Vado reference product
            if (referenceDatasFromMachine && referenceDatasFromMachine.vadoReferenceProduct) {
                setVadoReferenceProduct(referenceDatasFromMachine.vadoReferenceProduct);
            }
            // Competitor reference product
            if (referenceDatasFromMachine && referenceDatasFromMachine.competitorReferenceProduct) {
                setCompetitorReferenceProduct(referenceDatasFromMachine.competitorReferenceProduct);
            }
            // Comparison data
            let filterComparisons = [];
            for (const f of machine.filters) {
                if (f.product) {
                    let comparisonDataFromMachine = null;
                    if (referenceDatasFromMachine && referenceDatasFromMachine.filterComparisonParameters) {
                        comparisonDataFromMachine = referenceDatasFromMachine.filterComparisonParameters.find(
                            (fcp) => fcp.id === f.id
                        );
                    }
                    if (comparisonDataFromMachine) {
                        filterComparisons.push({
                            id: comparisonDataFromMachine.id,
                            bpProductId: null,
                            count: comparisonDataFromMachine.count,
                            width: comparisonDataFromMachine.width,
                            height: comparisonDataFromMachine.height,
                            depth: comparisonDataFromMachine.depth,
                            numberOfPockets: comparisonDataFromMachine.numberOfPockets,
                        });
                    } else {
                        filterComparisons.push({
                            id: f.id,
                            bpProductId: null,
                            count: f.count,
                            width: f.product.dimensionWidthMm,
                            height: f.product.dimensionHeightMm,
                            depth: f.product.dimensionDepthMm,
                            numberOfPockets: f.product.numberOfPockets,
                        });
                    }
                } else {
                    for (const bp of f.bundle.products) {
                        let comparisonDataFromMachine = null;
                        if (referenceDatasFromMachine && referenceDatasFromMachine.filterComparisonParameters) {
                            comparisonDataFromMachine = referenceDatasFromMachine.filterComparisonParameters.find(
                                (fcp) => fcp.id === f.id && fcp.bpProductId === bp.product.id
                            );
                        }
                        if (comparisonDataFromMachine) {
                            filterComparisons.push({
                                id: comparisonDataFromMachine.id,
                                bpProductId: comparisonDataFromMachine.bpProductId,
                                count: comparisonDataFromMachine.count,
                                width: comparisonDataFromMachine.width,
                                height: comparisonDataFromMachine.height,
                                depth: comparisonDataFromMachine.depth,
                                numberOfPockets: comparisonDataFromMachine.numberOfPockets,
                            });
                        } else {
                            filterComparisons.push({
                                id: f.id,
                                bpProductId: bp.product.id,
                                count: f.count * bp.productCount,
                                width: bp.product.dimensionWidthMm,
                                height: bp.product.dimensionHeightMm,
                                depth: bp.product.dimensionDepthMm,
                                numberOfPockets: bp.product.numberOfPockets,
                            });
                        }
                    }
                }
            }
            setFilterComparisons(filterComparisons);
        }
    }, [machine, referenceDatasFromMachine]);

    useEffect(() => {
        if (
            machine &&
            vadoReferenceFilterFromEuroventData &&
            (!machine.refData || !machine.refData.vadoReferenceProduct)
        ) {
            setVadoReferenceProduct(vadoReferenceFilterFromEuroventData);
        }
    }, [vadoReferenceFilterFromEuroventData, machine]);

    // object with machine id, each object:
    // - id (machine Id)
    // - vadoRefProduct
    // - compRefProduct
    // - compFilterParameters, array of:
    //   - id (location filter id)
    //   - count
    //   - width
    //   - height
    //   - depth
    //   - numberOfPockets
    useEffect(() => {
        if (filterComparisonParameters && filterComparisonParameters.length > 0) {
            updateCallBack({
                machineId: machine.id,
                vadoReferenceProduct: vadoReferenceProduct,
                competitorReferenceProduct: competitorReferenceProduct,
                filterComparisonParameters: filterComparisonParameters,
            });
        }
    }, [vadoReferenceProduct, competitorReferenceProduct, filterComparisonParameters, machine, updateCallBack]);

    const getFilterComparison = (fId, prodBundleProdId) => {
        if (filterComparisonParameters && filterComparisonParameters.length > 0) {
            if (prodBundleProdId) {
                return filterComparisonParameters.find((f) => f.id === fId && f.bpProductId === prodBundleProdId);
            }
            return filterComparisonParameters.find((f) => f.id === fId);
        }
        return null;
    };

    const getFilterComparisonIndex = (fId, prodBundleProdId) => {
        if (filterComparisonParameters && filterComparisonParameters.length > 0) {
            if (prodBundleProdId) {
                return filterComparisonParameters.findIndex((f) => f.id === fId && f.bpProductId === prodBundleProdId);
            }
            return filterComparisonParameters.findIndex((f) => f.id === fId);
        }
        throw new Error('error');
    };

    const onChangeCompFilterCount = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filterComparisonParameters);
        const newFilt = cloneDeep(getFilterComparison(fId, bpProdId));
        const ind = getFilterComparisonIndex(fId, bpProdId);
        newFilt.count = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFilterComparisons(newFilters);
    };
    const onChangeCompFilterWidth = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filterComparisonParameters);
        const newFilt = cloneDeep(getFilterComparison(fId, bpProdId));
        const ind = getFilterComparisonIndex(fId, bpProdId);
        newFilt.width = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFilterComparisons(newFilters);
    };
    const onChangeCompFilterheight = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filterComparisonParameters);
        const newFilt = cloneDeep(getFilterComparison(fId, bpProdId));
        const ind = getFilterComparisonIndex(fId, bpProdId);
        newFilt.height = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFilterComparisons(newFilters);
    };
    const onChangeCompFilterDepth = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filterComparisonParameters);
        const newFilt = cloneDeep(getFilterComparison(fId, bpProdId));
        const ind = getFilterComparisonIndex(fId, bpProdId);
        newFilt.depth = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFilterComparisons(newFilters);
    };
    const onChangeCompFilterPockets = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filterComparisonParameters);
        const newFilt = cloneDeep(getFilterComparison(fId, bpProdId));
        const ind = getFilterComparisonIndex(fId, bpProdId);
        newFilt.numberOfPockets = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFilterComparisons(newFilters);
    };

    const onSelectVadoRef = (event) => {
        const refProd = vadoReferenceProductsInEuroventData.find((p) => p.product.productName === event.target.value);
        setVadoReferenceProduct(refProd);
    };

    const deselectCompRef = () => {
        setCompetitorReferenceProduct(null);
    };

    const onSelectCompRef = (event) => {
        const selected = filteredCompetitorReferenceProducts.find((p) => p.storageId === event.target.value);
        let newFilters = cloneDeep(filterComparisonParameters);
        let changes = false;
        if (selected && newFilters && newFilters.length) {
            for (let f of newFilters) {
                if (selected.depthOrLengthMm_21 && selected.depthOrLengthMm_21.length) {
                    changes = true;
                    f.depth = parseInt(selected.depthOrLengthMm_21);
                }
                if (selected.noOfBagsOrVs_23 && selected.noOfBagsOrVs_23.length) {
                    changes = true;
                    f.numberOfPockets = parseInt(selected.noOfBagsOrVs_23);
                }
            }
        }
        if (changes) setFilterComparisons(newFilters);
        setCompetitorReferenceProduct(selected);
    };

    return (
        <React.Fragment key={`machine-row-main-fragment-${machine.id}`}>
            <TableRow key={`machine-row-machine-${machine.id}`}>
                <TableCell></TableCell>
                <TableCell>
                    <strong>{machine.name},&nbsp;</strong>
                    {t('location.airVolume')}: <strong>{machine.airVolume} m&#179;/s</strong>, {t('location.position')}:{' '}
                    <strong>{machine.machineLocation}</strong>, {t('location.serviceArea')}:{' '}
                    <strong>{machine.machineArea}</strong>
                    ,&nbsp;<strong>Pinta-ala</strong>:&nbsp;{machine.totalSurfaceArea()} m2
                    <br />
                    {vadoReferenceProductsInEuroventData && (
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id={`${machine.id}-vado-ref-select-label`}>Vado referenssituote</InputLabel>
                            <Select
                                required
                                error={false}
                                value={
                                    vadoReferenceProduct &&
                                    vadoReferenceProduct.product &&
                                    vadoReferenceProduct.product.productName
                                        ? vadoReferenceProduct.product.productName
                                        : DEFAULT_SELECTION
                                }
                                labelId={`${machine.id}-vado-ref-select-label`}
                                id={`${machine.id}-vado-ref-select`}
                                name="vado-ref-product-select"
                                onChange={(e) => onSelectVadoRef(e)}>
                                <MenuItem value={DEFAULT_SELECTION} key={'productName-default'}>
                                    -Valitse-
                                </MenuItem>
                                {vadoReferenceProductsInEuroventData &&
                                    vadoReferenceProductsInEuroventData.map((prod) => (
                                        <MenuItem
                                            value={prod.product.productName}
                                            key={`productName-${prod.product.productName}`}>
                                            {prod.product.productName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    {!competitorReferenceProduct && filteredCompetitorReferenceProducts && (
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id={`${machine.id}-competitor-product-select-label`}>
                                {t('product.competitorProductName')}
                            </InputLabel>
                            <Select
                                required
                                error={false}
                                value={
                                    competitorReferenceProduct && competitorReferenceProduct.storageId
                                        ? competitorReferenceProduct.storageId
                                        : DEFAULT_SELECTION
                                }
                                labelId={`${machine.id}-competitor-product-select-label`}
                                id={`${machine.id}-competitor-product-select`}
                                name="competitor-product-select"
                                onChange={(e) => onSelectCompRef(e)}>
                                <MenuItem value={DEFAULT_SELECTION} key={'comp-ref-product-default'}>
                                    -Valitse-
                                </MenuItem>
                                {filteredCompetitorReferenceProducts &&
                                    filteredCompetitorReferenceProducts.map((prod) => (
                                        <MenuItem value={prod.storageId} key={`comp-ref-product-${prod.storageId}`}>
                                            {prod.model_3}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                    {competitorReferenceProduct && (
                        <React.Fragment>
                            <span className={classes.selectedCompProdHint}>Kilpailijan tuote</span>
                            <br />
                            <Grid container>
                                <Grid item xs={11} alignContent="center">
                                    <span className={classes.selectedCompProd}>
                                        {competitorReferenceProduct.model_3}
                                    </span>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton aria-label="delete" color="primary" onClick={deselectCompRef}>
                                        <CancelIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </TableCell>
            </TableRow>
            {machine.filters.map((f) => (
                <React.Fragment key={`fragment-${machine.id}-${f.id}`}>
                    {f.product &&
                        (() => {
                            const filterComparison = getFilterComparison(f.id);
                            return (
                                <TableRow key={`table-row-${machine.id}-${f.id}`}>
                                    <TableCell>{f.count}</TableCell>
                                    <TableCell>{f.product.productName}</TableCell>
                                    <TableCell>
                                        {filterComparison && (
                                            <TextField
                                                label={'kpl'}
                                                value={filterComparison.count}
                                                onChange={(e) => onChangeCompFilterCount(f.id, null, e)}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {filterComparison && (
                                            <Grid item container>
                                                <Grid item>
                                                    <TextField
                                                        className={classes.text}
                                                        label={'Leveys'}
                                                        value={filterComparison.width || ''}
                                                        onChange={(e) => onChangeCompFilterWidth(f.id, null, e)}
                                                    />
                                                    <TextField
                                                        className={classes.text}
                                                        label={'Korkeus'}
                                                        value={filterComparison.height || ''}
                                                        onChange={(e) => onChangeCompFilterheight(f.id, null, e)}
                                                    />
                                                    <TextField
                                                        className={classes.text}
                                                        label={'Syvyys'}
                                                        value={filterComparison.depth || ''}
                                                        onChange={(e) => onChangeCompFilterDepth(f.id, null, e)}
                                                    />
                                                    <TextField
                                                        className={classes.text}
                                                        label={'Taskuja'}
                                                        value={filterComparison.numberOfPockets || ''}
                                                        onChange={(e) => onChangeCompFilterPockets(f.id, null, e)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })()}
                    {f.bundle && (
                        <React.Fragment key={`fragment-bundle-${f.bundle.id}`}>
                            {f.bundle.products.map((bp) =>
                                (() => {
                                    const filterComparison = getFilterComparison(f.id, bp.product.id);
                                    return (
                                        <TableRow id={bp.product.id} key={`bundles-${bp.product.id}`}>
                                            <TableCell>{f.count * bp.productCount}</TableCell>
                                            <TableCell>
                                                {bp.product.productName} ({f.bundle.name})
                                            </TableCell>
                                            <TableCell>
                                                {filterComparison && (
                                                    <TextField
                                                        label={'kpl'}
                                                        value={filterComparison.count || ''}
                                                        onChange={(e) =>
                                                            onChangeCompFilterCount(f.id, bp.product.id, e)
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {filterComparison && (
                                                    <Grid item container>
                                                        <Grid item>
                                                            <TextField
                                                                className={classes.text}
                                                                label={'Leveys'}
                                                                value={filterComparison.width || ''}
                                                                onChange={(e) =>
                                                                    onChangeCompFilterWidth(f.id, bp.product.id, e)
                                                                }
                                                            />
                                                            <TextField
                                                                className={classes.text}
                                                                label={'Korkeus'}
                                                                value={filterComparison.height || ''}
                                                                onChange={(e) =>
                                                                    onChangeCompFilterheight(f.id, bp.product.id, e)
                                                                }
                                                            />
                                                            <TextField
                                                                className={classes.text}
                                                                label={'Syvyys'}
                                                                value={filterComparison.depth || ''}
                                                                onChange={(e) =>
                                                                    onChangeCompFilterDepth(f.id, bp.product.id, e)
                                                                }
                                                            />
                                                            <TextField
                                                                className={classes.text}
                                                                label={'Taskuja'}
                                                                value={filterComparison.numberOfPockets || ''}
                                                                onChange={(e) =>
                                                                    onChangeCompFilterPockets(f.id, bp.product.id, e)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })()
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
