import CapacityGroup from './capacitygroup';

class CapacityGroupCapacityAllocation {
    constructor(json) {
        if (json) {
            this.capacityGroup = new CapacityGroup(json.capacityGroup);
            this.year = json.year;
            this.week = json.week;
            this.allocatedCapacity = json.allocatedCapacity;
        } else {
            this.capacityGroup = new CapacityGroup();
            this.year = 0;
            this.week = 0;
            this.allocatedCapacity = 0;
        }
    }
}

export default CapacityGroupCapacityAllocation;
