import React, { useState, useEffect } from 'react';
import Editor from '@draft-js-plugins/editor';
import {
    EditorState,
    ContentState,
    convertFromHTML,
    Modifier,
    SelectionState,
    getDefaultKeyBinding,
    RichUtils,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import PropTypes from 'prop-types';
import { IconButton, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    editorContainer: {
        border: '1px solid #ddd',
        minHeight: 200,
        padding: 10,
    },
    toolbar: {
        display: 'flex',
        gap: '8px',
    },
    boldIcon: {
        fontWeight: 'bold',
        fontSize: '1.25rem',
    },
    italicIcon: {
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '1.25rem',
    },
    headerIcon: {
        fontWeight: 'bold',
    },
    iconButton: {
        width: 'auto',
        padding: '6px 16px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        transition: 'background-color 0.2s ease',
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    default: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.primary.secondary,
        },
    },
}));

export default function RichTextEditor(props) {
    const classes = useStyles();
    const dataDecoded = Buffer.from(props.initialData, 'base64').toString('utf-8');
    const blocksFromHTML = convertFromHTML(dataDecoded);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    // Set up the editor state
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState));

    useEffect(() => {
        if (typeof props.getEditorContentHtml === 'function') {
            props.getEditorContentHtml(() => {
                const currentContent = editorState.getCurrentContent();
                const htmlContent = stateToHTML(currentContent);
                return Buffer.from(htmlContent, 'utf-8').toString('base64');
            });
        }
    }, [editorState, props]);

    const customKeyBindingFn = (e) => {
        if (e.key === 'Enter') {
            return 'split-block';
        }
        return getDefaultKeyBinding(e);
    };

    const handleKeyCommand = (command, editorState, setEditorState) => {
        if (command === 'split-block') {
            const selection = editorState.getSelection();
            const contentState = editorState.getCurrentContent();
            const blockType = contentState.getBlockForKey(selection.getStartKey()).getType();

            if (blockType === 'header-one' || blockType === 'header-two') {
                // Split the block
                const newContentState = Modifier.splitBlock(contentState, selection);

                // Get the key of the new block
                const newBlockKey = newContentState.getKeyAfter(selection.getStartKey());

                // Change the new block to 'unstyled'
                const updatedContentState = Modifier.setBlockType(
                    newContentState,
                    SelectionState.createEmpty(newBlockKey),
                    'unstyled'
                );

                // Push the updated content state
                let newEditorState = EditorState.push(editorState, updatedContentState, 'split-block');

                // Move the selection to the new block
                const newSelection = SelectionState.createEmpty(newBlockKey);
                newEditorState = EditorState.forceSelection(newEditorState, newSelection);

                // Set editorstate
                setEditorState(newEditorState);

                return 'handled';
            }
        }
        return 'not-handled';
    };

    function CustomToolbar({ editorState, setEditorState }) {
        const currentBlockType = editorState
            .getCurrentContent()
            .getBlockForKey(editorState.getSelection().getStartKey())
            .getType();

        const currentInlineStyle = editorState.getCurrentInlineStyle();

        const toggleBlockType = (blockType) => {
            const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
            setEditorState(newEditorState);
        };

        const toggleInlineStyle = (style) => {
            const newEditorState = RichUtils.toggleInlineStyle(editorState, style);
            setEditorState(newEditorState);
        };

        const isActive = (style) => currentInlineStyle.has(style);

        return (
            <div style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', paddingBottom: '5px' }}>
                <div className={classes.toolbar}>
                    <IconButton
                        onMouseDown={(e) => {
                            e.preventDefault();
                            toggleBlockType('header-one');
                        }}
                        className={`${classes.iconButton} ${
                            currentBlockType === 'header-one' ? classes.active : classes.default
                        }`}>
                        <Typography variant="h6">H1</Typography>
                    </IconButton>

                    <IconButton
                        onMouseDown={(e) => {
                            e.preventDefault();
                            toggleBlockType('header-two');
                        }}
                        className={`${classes.iconButton} ${
                            currentBlockType === 'header-two' ? classes.active : classes.default
                        }`}>
                        <Typography variant="h6">H2</Typography>
                    </IconButton>

                    <IconButton
                        onMouseDown={(e) => {
                            e.preventDefault();
                            toggleBlockType('unstyled');
                        }}
                        className={`${classes.iconButton} ${
                            currentBlockType === 'unstyled' ? classes.active : classes.default
                        }`}>
                        <Typography variant="body1">teksti</Typography>
                    </IconButton>

                    <IconButton
                        onMouseDown={(e) => {
                            e.preventDefault();
                            toggleInlineStyle('BOLD');
                        }}
                        className={`${classes.iconButton} ${isActive('BOLD') ? classes.active : classes.default}`}>
                        <Typography variant="body1" className={classes.boldIcon}>
                            B
                        </Typography>
                    </IconButton>

                    <IconButton
                        onMouseDown={(e) => {
                            e.preventDefault();
                            toggleInlineStyle('ITALIC');
                        }}
                        className={`${classes.iconButton} ${isActive('ITALIC') ? classes.active : classes.default}`}>
                        <Typography variant="body1" className={classes.italicIcon}>
                            I
                        </Typography>
                    </IconButton>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.editorContainer}>
            {!props.viewOnly && <CustomToolbar editorState={editorState} setEditorState={setEditorState} />}
            <Editor
                editorState={editorState}
                onChange={setEditorState}
                readOnly={props.viewOnly}
                keyBindingFn={customKeyBindingFn}
                handleKeyCommand={(command) => handleKeyCommand(command, editorState, setEditorState)}
            />
        </div>
    );
}

RichTextEditor.propTypes = {
    htmlContent: PropTypes.string,
    viewOnly: PropTypes.bool.isRequired,
    getEditorContentHtml: PropTypes.func,
};
