import React, { useEffect, useState } from 'react';
import SurveyView from './surveyView';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../common/spinner';
import { getSurvey, getSurveyEnrichment } from '../modules/surveydata';

export default function SurveyViewContainer(props) {
    const id = props.match.params.surveyId;
    const dispatch = useDispatch();
    const features = useSelector((state) => state.settingsdata.features);
    const surveysInUse = features != null ? features.surveysInUse : false;
    const [survey, setSurvey] = useState(null);
    const [surveyEnrichment, setSurveyEnrichment] = useState(null);

    useEffect(() => {
        async function getSurveyData() {
            var resp = await getSurvey(id)(dispatch);
            setSurvey(resp);
        }
        async function getSurveyEnrichmentData() {
            var resp = await getSurveyEnrichment(id)(dispatch);
            setSurveyEnrichment(resp);
        }
        if (surveysInUse && !survey) {
            getSurveyData();
            getSurveyEnrichmentData();
        }
    }, [id, survey, surveysInUse, surveyEnrichment, dispatch]);

    if (!survey || !surveyEnrichment) {
        return <Spinner />;
    }
    //TODO: surveyenrichment haetaan jo surveyView moduulissa. Voidaan optimoida.
    return (
        <SurveyView survey={survey} surveyEnrichment={surveyEnrichment} history={props.history} notExpandable={true} />
    );
}
