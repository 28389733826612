import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/spinner';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import {
    getLocationFull as getLocationFullSales,
    getLocationEnergyReportExcel,
    getEnergyCalculationData,
    saveEnergyCalculationData,
} from '../modules/locationdata';
import { fullscreenSpinnerOn, fullscreenSpinnerOff } from '../modules/appstate';
import { getLocationFull as getLocationFullCustomer } from '../modules/customerlocationdata';
import { cloneDeep, uniqBy } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { getEuroventReferenceData } from '../modules/productdata';
import FilterProduct from '../domain/filterproduct';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MachineRow from './machinerow';

const DEFAULT_SELECTION = '-';

const delay = async (ms) => {
    await new Promise((r) => setTimeout(r, ms));
};

let saveTimer = null;

export default function LocationViewEnergy(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customerUi = process.env.REACT_APP_TARGET === 'customerui';
    const locationId = parseInt(props.match.params.locationId, 10);
    const locationsByIdCust = useSelector((state) => state.customerlocationdata.locationsById);
    const locationsByIdVado = useSelector((state) => state.locationdata.locationsById);
    const locationFull = customerUi ? locationsByIdCust[locationId] : locationsByIdVado[locationId];
    const products = useSelector((state) => state.productdata.filterProducts);
    const [reff] = useState(React.createRef());
    const [blobUrl, setBlobUrl] = useState('');

    const [euroventData, setEuroventData] = useState(null);
    const [vadoEuroventData, setVadoEuroventData] = useState(null);
    const [vadoReferenceFilterByMachineId, setVadoReferenceFilterByMachineId] = useState(null);
    const [loadingEnergyXlsx, setLoadingEnergyXlsx] = useState(false);
    const [selectEPMClasses, setSelectEPMClasses] = useState([]);
    const [selectBrands, setSelectBrands] = useState([]);
    const [selectEffPercents, setSelectEffPercents] = useState([]);
    const [selectRanges, setSelectRanges] = useState([]);

    const [selectedBrand, setSelectedBrand] = useState(DEFAULT_SELECTION);
    const [selectedRange, setSelectedRange] = useState(DEFAULT_SELECTION);
    const [selectedEPMClass, setSelectedEPMClass] = useState(DEFAULT_SELECTION);
    const [selectedEffPercent, setSelectedEffPercent] = useState(DEFAULT_SELECTION);
    const [filteredCompetitorReferenceProducts, setFilteredCompetitorReferenceProducts] = useState([]);
    const [locationReferenceProductSelections, setLocationReferenceProductSelections] = useState(null);
    const [referenceDatasPerMachineId, setReferenceDatasPerMachineId] = useState(null);
    const [initialDataLoadingDone, setInitialDataLoadingDone] = useState(false);
    // object with machine id, each object:
    // - id (machine Id)
    // - vadoRefProduct
    // - compRefProduct
    // - compFilterParameters, array of:
    //   - id (location filter id)
    //   - count
    //   - width
    //   - height
    //   - depth
    //   - numberOfPockets
    const refDatasPerMachineRef = useRef({});

    useEffect(() => {
        async function getData() {
            dispatch(fullscreenSpinnerOn());
            var euroventReferenceData = await getEuroventReferenceData();
            setEuroventData(euroventReferenceData);
            const vadoFiltersInEurovent = euroventReferenceData
                .filter((filt) => filt.brand_1 === 'VADO')
                .map((item) => ({
                    product: FilterProduct.ParseProductFromEuroventName(item.model_3),
                    euroventData: {
                        ...item,
                        curve: [
                            { avp: 50, pdPa: parseInt(item.initPressDropAt50percentPa_11) },
                            { avp: 75, pdPa: parseInt(item.initPressDropAt75percentPa_12) },
                            { avp: 100, pdPa: parseInt(item.initPressDropAt100percentPa_10) },
                            { avp: 125, pdPa: parseInt(item.initPressDropAt125percentPa_13) },
                        ],
                    },
                }));
            setVadoEuroventData(vadoFiltersInEurovent);
            if (locationId) {
                var locationEnergyCalculationData = await getEnergyCalculationData(locationId);
                if (locationEnergyCalculationData) {
                    if (locationEnergyCalculationData.referenceProductSelections) {
                        setLocationReferenceProductSelections(locationEnergyCalculationData.referenceProductSelections);
                    }
                    if (locationEnergyCalculationData.referenceDataInputs) {
                        setReferenceDatasPerMachineId(locationEnergyCalculationData.referenceDataInputs);
                    }
                }
            }
            setInitialDataLoadingDone(true);
            dispatch(fullscreenSpinnerOff());
        }
        getData();
    }, [locationId, dispatch]);

    const findVadoReferenceFilterFromEuroventData = useCallback(
        (prodName) => {
            const toMatch = products.filter((f) => f.productName === prodName)[0];
            if (!toMatch) return null;

            const vadoFiltersInEurovent = euroventData
                .filter((filt) => filt.brand_1 === 'VADO')
                .map((item) => ({
                    product: FilterProduct.ParseProductFromEuroventName(item.model_3),
                    euroventData: {
                        ...item,
                        curve: [
                            { avp: 50, pdPa: parseInt(item.initPressDropAt50percentPa_11) },
                            { avp: 75, pdPa: parseInt(item.initPressDropAt75percentPa_12) },
                            { avp: 100, pdPa: parseInt(item.initPressDropAt100percentPa_10) },
                            { avp: 125, pdPa: parseInt(item.initPressDropAt125percentPa_13) },
                        ],
                    },
                }));
            // 1) find direct match (all dimensions, material, pockets)
            let match = vadoFiltersInEurovent.filter(
                (f) =>
                    f.product.type === toMatch.legacyType &&
                    f.product.material === toMatch.material &&
                    f.product.filterClass === toMatch.filterClass &&
                    f.product.classP === toMatch.classP &&
                    f.product.dimensionWidthMm === toMatch.dimensionWidthMm &&
                    f.product.dimensionHeightMm === toMatch.dimensionHeightMm &&
                    f.product.dimensionDepthMm === toMatch.dimensionDepthMm &&
                    f.product.numberOfPockets === toMatch.numberOfPockets
            );
            //if (match.length > 0) {
            //match[0].exactMatch = true;
            //}

            // 2) Find match with same depth & pockets & width but different height
            if (match.length === 0)
                match = vadoFiltersInEurovent.filter(
                    (f) =>
                        f.product.type === toMatch.legacyType &&
                        f.product.material === toMatch.material &&
                        f.product.filterClass === toMatch.filterClass &&
                        f.product.classP === toMatch.classP &&
                        f.product.dimensionWidthMm === toMatch.dimensionWidthMm &&
                        f.product.dimensionDepthMm === toMatch.dimensionDepthMm &&
                        f.product.numberOfPockets === toMatch.numberOfPockets
                );

            // 3) Find match with same depth & pockets & height but different width
            if (match.length === 0)
                match = vadoFiltersInEurovent.filter(
                    (f) =>
                        f.product.type === toMatch.legacyType &&
                        f.product.material === toMatch.material &&
                        f.product.filterClass === toMatch.filterClass &&
                        f.product.classP === toMatch.classP &&
                        f.product.dimensionHeightMm === toMatch.dimensionHeightMm &&
                        f.product.dimensionDepthMm === toMatch.dimensionDepthMm &&
                        f.product.numberOfPockets === toMatch.numberOfPockets
                );

            // 4) Find match with same depth & pockets but width&height differs
            if (match.length === 0)
                match = vadoFiltersInEurovent.filter(
                    (f) =>
                        f.product.type === toMatch.legacyType &&
                        f.product.material === toMatch.material &&
                        f.product.filterClass === toMatch.filterClass &&
                        f.product.classP === toMatch.classP &&
                        f.product.dimensionDepthMm === toMatch.dimensionDepthMm &&
                        f.numberOfPockets === toMatch.numberOfPockets
                );
            return match.length > 0 ? match[0] : null;
        },
        [euroventData, products]
    );

    useEffect(() => {
        if (euroventData && locationFull && products) {
            const filterMapMachines = {};

            if (locationFull.machines) {
                for (const m of locationFull.machines) {
                    if (m.filters && m.filters.length > 0) {
                        if (m.filters[0].product) {
                            filterMapMachines[m.id] = findVadoReferenceFilterFromEuroventData(
                                m.filters[0].product.productName
                            );
                        } else {
                            filterMapMachines[m.id] = findVadoReferenceFilterFromEuroventData(
                                m.filters[0].bundle.products[0].product.productName
                            );
                        }
                    }
                }
            }
            setVadoReferenceFilterByMachineId(filterMapMachines);
            setSelectBrands(uniqBy(euroventData, 'brand_1').map((elem) => elem.brand_1));
            if (locationReferenceProductSelections && locationReferenceProductSelections.selectedBrand) {
                setSelectedBrand(locationReferenceProductSelections.selectedBrand);
            }
        }
    }, [
        euroventData,
        locationFull,
        locationReferenceProductSelections,
        products,
        findVadoReferenceFilterFromEuroventData,
    ]);

    useEffect(() => {
        if (!locationFull) {
            if (customerUi) dispatch(getLocationFullCustomer(locationId));
            else dispatch(getLocationFullSales(locationId));
        }
    }, [locationFull, customerUi, dispatch, locationId]);

    useEffect(() => {
        if (selectedBrand !== DEFAULT_SELECTION) {
            setSelectRanges(
                uniqBy(
                    euroventData.filter((r) => r.brand_1 === selectedBrand),
                    'range_2'
                ).map((elem) => elem.range_2)
            );
            if (locationReferenceProductSelections && locationReferenceProductSelections.selectedRange) {
                setSelectedRange(locationReferenceProductSelections.selectedRange);
            }
        } else {
            setSelectRanges([]);
        }
    }, [selectedBrand, locationReferenceProductSelections, euroventData]);

    useEffect(() => {
        if (selectedRange !== DEFAULT_SELECTION && selectedBrand !== DEFAULT_SELECTION) {
            setSelectEPMClasses(
                uniqBy(
                    euroventData.filter((r) => r.brand_1 === selectedBrand && r.range_2 === selectedRange),
                    'ePMGroupISO16890_5'
                ).map((elem) => elem.ePMGroupISO16890_5)
            );
            if (locationReferenceProductSelections && locationReferenceProductSelections.selectedEPMClass) {
                setSelectedEPMClass(locationReferenceProductSelections.selectedEPMClass);
            }
        } else {
            setSelectEPMClasses([]);
        }
    }, [selectedRange, locationReferenceProductSelections, euroventData, selectedBrand]);

    useEffect(() => {
        if (
            selectedEPMClass !== DEFAULT_SELECTION &&
            selectedBrand !== DEFAULT_SELECTION &&
            selectedRange !== DEFAULT_SELECTION
        ) {
            setSelectEffPercents(
                uniqBy(
                    euroventData.filter(
                        (r) =>
                            r.brand_1 === selectedBrand &&
                            r.range_2 === selectedRange &&
                            r.ePMGroupISO16890_5 === selectedEPMClass
                    ),
                    'efficiencyClassISO16890Percent_7'
                ).map((elem) => elem.efficiencyClassISO16890Percent_7)
            );
            if (locationReferenceProductSelections && locationReferenceProductSelections.selectedEffPercent) {
                setSelectedEffPercent(locationReferenceProductSelections.selectedEffPercent);
            }
        } else {
            setSelectEffPercents([]);
        }
    }, [selectedEPMClass, locationReferenceProductSelections, euroventData, selectedBrand, selectedRange]);

    useEffect(() => {
        let filteredList = euroventData;
        if (selectedBrand !== DEFAULT_SELECTION) {
            filteredList = filteredList.filter((r) => r.brand_1 === selectedBrand);
        }
        if (selectedRange !== DEFAULT_SELECTION) {
            filteredList = filteredList.filter((r) => r.range_2 === selectedRange);
        }
        if (selectedEPMClass !== DEFAULT_SELECTION) {
            filteredList = filteredList.filter((r) => r.ePMGroupISO16890_5 === selectedEPMClass);
        }
        if (selectedEffPercent !== DEFAULT_SELECTION) {
            filteredList = filteredList.filter((r) => r.efficiencyClassISO16890Percent_7 === selectedEffPercent);
        }
        setFilteredCompetitorReferenceProducts(filteredList);
    }, [selectedBrand, selectedEPMClass, selectedEffPercent, selectedRange, euroventData]);

    const loadLocationEnergyXlsx = async (e) => {
        if (blobUrl) {
            e.stopPropagation();
            await delay(100);
            setBlobUrl('');
            setLoadingEnergyXlsx(false);
            return;
        }
        setLoadingEnergyXlsx(true);

        const refDatas = locationFull.machines.map((m) => {
            return {
                machine: m,
                parameters: refDatasPerMachineRef.current[m.id],
            };
        });

        const xlsx = await getLocationEnergyReportExcel(locationId, refDatas);

        if (xlsx) {
            const buff = Buffer.from(xlsx, 'base64');
            const blob = new Blob([buff.buffer], {
                type: 'application/vnd.openxmlformats',
            });
            const url = URL.createObjectURL(blob);
            setBlobUrl(url);

            setTimeout(() => {
                reff.current.click();
            }, 10);
        } else {
            setLoadingEnergyXlsx(false);
        }
    };

    const close = () => {
        props.history.goBack();
    };

    const onSelectBrand = (elem) => {
        const brand = elem.target.value;
        setSelectedBrand(brand);
        setSelectedRange(DEFAULT_SELECTION);
        setSelectedEPMClass(DEFAULT_SELECTION);
        setSelectedEffPercent(DEFAULT_SELECTION);
        setLocationReferenceProductSelections(null);
    };

    const onSelectRange = (elem) => {
        const range = elem.target.value;
        setSelectedRange(range);
        setSelectedEPMClass(DEFAULT_SELECTION);
        setSelectedEffPercent(DEFAULT_SELECTION);
    };

    const onSelectEPMClass = (elem) => {
        const epmClass = elem.target.value;
        setSelectedEPMClass(epmClass);
        setSelectedEffPercent(DEFAULT_SELECTION);
    };

    const onSelectEffPercent = (elem) => {
        const effPercent = elem.target.value;
        setSelectedEffPercent(effPercent);
    };

    const saveChanges = () => {
        saveTimer = null;
        const data = {
            referenceProductSelections: {
                selectedBrand: selectedBrand,
                selectedRange: selectedRange,
                selectedEPMClass: selectedEPMClass,
                selectedEffPercent: selectedEffPercent,
            },
            referenceDataInputs: refDatasPerMachineRef.current,
        };
        saveEnergyCalculationData(locationId, data);
    };

    const updateMachineParameters = (data) => {
        if (!initialDataLoadingDone) return;
        const refDatasNew = cloneDeep(refDatasPerMachineRef.current);
        refDatasNew[data.machineId] = data;
        refDatasPerMachineRef.current = refDatasNew;

        clearTimeout(saveTimer);
        saveTimer = setTimeout(() => {
            saveChanges();
        }, 2000);
    };

    if (customerUi) return null;

    return (
        <Container maxWidth={false}>
            <Grid item xs={12}>
                {locationFull != null && (
                    <h2>
                        <Button onClick={close}>
                            <i className="fas fa-chevron-left fa-2x"></i>
                            &nbsp;&nbsp;
                        </Button>
                        {locationFull.name}
                    </h2>
                )}
                {!locationFull && <Spinner padding="20px" title={t('general.loading')} />}
                {locationFull && (
                    <Grid item>
                        {!customerUi && (
                            <Grid item>
                                <h3>Vertailutuotevalinnat</h3>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="select-brand-label">Merkki</InputLabel>
                                        <Select
                                            required
                                            error={false}
                                            labelId="select-brand-label"
                                            id="select-brand"
                                            name="select-brand"
                                            value={selectedBrand}
                                            onChange={onSelectBrand}>
                                            <MenuItem value={DEFAULT_SELECTION}>-Valitse-</MenuItem>
                                            {selectBrands &&
                                                selectBrands.map((elem) => (
                                                    <MenuItem id={elem} value={elem} key={'brand-' + elem}>
                                                        {elem}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="select-range-label">Range</InputLabel>
                                        <Select
                                            required
                                            error={false}
                                            labelId="select-range-label"
                                            id="select-range"
                                            name="select-range"
                                            value={selectedRange}
                                            onChange={onSelectRange}>
                                            <MenuItem value={DEFAULT_SELECTION}>-Valitse-</MenuItem>
                                            {selectRanges &&
                                                selectRanges.map((elem) => (
                                                    <MenuItem id={elem} value={elem} key={'range-' + elem}>
                                                        {elem}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="select-epm-class-label">ePM</InputLabel>
                                        <Select
                                            required
                                            error={false}
                                            labelId="select-epm-class-label"
                                            id="select-epm-class"
                                            name="select-epm-class"
                                            value={selectedEPMClass}
                                            onChange={onSelectEPMClass}>
                                            <MenuItem value={DEFAULT_SELECTION}>-Valitse-</MenuItem>
                                            {selectEPMClasses &&
                                                selectEPMClasses.map((elem) => (
                                                    <MenuItem id={elem} value={elem} key={'epmClass-' + elem}>
                                                        {elem}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="select-eff-percent-label">Suodatustehokkuus %</InputLabel>
                                        <Select
                                            required
                                            error={false}
                                            labelId="select-eff-percent-label"
                                            id="select-eff-percent"
                                            name="select-eff-percent"
                                            value={selectedEffPercent}
                                            onChange={onSelectEffPercent}>
                                            <MenuItem value={DEFAULT_SELECTION}>-Valitse-</MenuItem>
                                            {selectEffPercents &&
                                                selectEffPercents.map((elem) => (
                                                    <MenuItem id={elem} value={elem} key={'efficiency-' + elem}>
                                                        {elem}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                        <br />

                        {locationFull.machines && (
                            <Table size="small">
                                <colgroup>
                                    <col width="5%" />
                                    <col width="50%" />
                                    <col width="5%" />
                                    <col width="50%" />
                                </colgroup>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Kpl</TableCell>
                                        <TableCell>Vado</TableCell>
                                        <TableCell>Kpl</TableCell>
                                        <TableCell>Kilpailija</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locationFull.machines.map((m) => {
                                        const referenceFilter = vadoReferenceFilterByMachineId
                                            ? vadoReferenceFilterByMachineId[m.id]
                                            : undefined;
                                        const referenceDatas = referenceDatasPerMachineId
                                            ? referenceDatasPerMachineId[m.id]
                                            : undefined;
                                        return (
                                            <MachineRow
                                                key={`machine-row-${m.id}`}
                                                machine={m}
                                                referenceDatasFromMachine={referenceDatas}
                                                referenceFilter={referenceFilter}
                                                vadoReferenceProductsInEuroventData={vadoEuroventData}
                                                filteredCompetitorReferenceProducts={
                                                    filteredCompetitorReferenceProducts
                                                }
                                                update={updateMachineParameters}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                    </Grid>
                )}
            </Grid>
            <br />
            <br />
            <Footer>
                {customerUi === false && (
                    <Grid item>
                        <Button
                            ref={reff}
                            variant="contained"
                            color="primary"
                            name="btn-load-location-energy-xsls"
                            onClick={loadLocationEnergyXlsx}
                            href={blobUrl.length > 0 ? blobUrl : null}
                            download={blobUrl.length > 0 ? `${locationFull.name}_${moment().format()}.xlsx` : null}
                            startIcon={
                                loadingEnergyXlsx ? (
                                    <CircularProgress color={'secondary'} size={'1rem'} />
                                ) : (
                                    <OfflineBoltIcon />
                                )
                            }>
                            {t('location.buttons.downloadExcel')}
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button variant="contained" color="secondary" name="btn-close" onClick={close}>
                        {t('buttons.close')}
                    </Button>
                </Grid>
                {customerUi === false && (
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Energialaskelma</FormLabel>
                            <Checkbox
                                checked={true}
                                onChange={() => {
                                    props.history.goBack();
                                }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </FormControl>
                    </Grid>
                )}
            </Footer>
            <FooterPadding />
        </Container>
    );
}
