class CapacityGroup {
    constructor(json) {
        if (json) {
            this.id = json.id;
            this.name = json.name;
        } else {
            this.id = null;
            this.name = '';
        }
    }
}

export default CapacityGroup;
