import ProductType from './producttype';
const dayjs = require('dayjs');

class AvailableCapacityPerDayAndProductType {
    constructor(json) {
        if (json) {
            this.productType = new ProductType(json.productType);
            this.availableCapacity = json.availableCapacity;
            this.day = dayjs(json.day);
        } else {
            this.productType = null;
            this.availableCapacity = 0;
            this.day = dayjs();
        }
    }
}

export default AvailableCapacityPerDayAndProductType;
