import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TabPanel from '../common/tabpanel';
import { Grid } from '@material-ui/core';
import UserManagementView from './usermanagementview';
import ProductionPlanning2 from './productionplanning2';
import ViewHelper from '../common/viewhelper';
import PortfolioManagementView from './portfoliomanagementview';
import { ParameterManagement } from './parametermanagement';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    tab: {
        width: '100%',
    },
}));

const AdminContainer = (props) => {
    const [selectedView, setSelectedView] = useState(0);
    const classes = useStyles();
    const { t } = useTranslation();

    const views = new ViewHelper({
        '#admin': 0,
        '#portfolio': 1,
        '#parameters': 2,
        '#productionplanning': 3,
        '#newproductionplanning': 4,
    });

    const handleChange = (event, value) => {
        props.history.replace('/admin' + views.getViewName(value));
        setSelectedView(value);
    };

    const selectViewFromUrl = () => {
        let correctView = views.getViewId(props.history.location.hash);
        if (selectedView !== correctView) {
            setSelectedView(correctView);
        }
    };

    useEffect(() => {
        selectViewFromUrl();
    });

    return (
        <Grid container>
            <AppBar position="static" color="default">
                <Box display="flex" justifyContent="center" width="100%">
                    <Tabs
                        value={selectedView}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto">
                        <Tab value={0} label={t('admin.users')} />
                        <Tab value={1} label={t('admin.portfolios')} />
                        <Tab value={2} label={t('admin.parameters')} />
                        <Tab value={3} label={t('admin.prodPlanning')} />
                    </Tabs>
                </Box>
            </AppBar>
            <TabPanel value={selectedView} index={0} className={classes.tab}>
                <UserManagementView history={props.history} active={selectedView === 0} />
            </TabPanel>
            <TabPanel value={selectedView} index={1} className={classes.tab}>
                <PortfolioManagementView history={props.history} active={selectedView === 1} />
            </TabPanel>
            <TabPanel value={selectedView} index={2} className={classes.tab}>
                <ParameterManagement history={props.history} active={selectedView === 2} />
            </TabPanel>
            <TabPanel value={selectedView} index={3} className={classes.tab}>
                <ProductionPlanning2 history={props.history} active={selectedView === 3} />
            </TabPanel>
        </Grid>
    );
};

export default AdminContainer;
