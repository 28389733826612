import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveCustomer, removeCustomer, getCustomerFull } from '../modules/customerdata';
import { getDuplicates } from '../modules/duplicatedata';
import CustomerEditView from './customereditview';
import Spinner from '../common/spinner';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export default function CustomerEditViewContainer(props) {
    const id = props.match.params.customerId !== 'new' ? parseInt(props.match.params.customerId, 10) : 'new';
    const dispatch = useDispatch();

    const customersById = useSelector((state) => state.customerdata.customersById);
    const getCustomerByIdInProgress = useSelector((state) => state.customerdata.getCustomerByIdInProgress);
    const getCustomerByIdFailed = useSelector((state) => state.customerdata.getCustomerByIdFailed);
    const savingCustomer = useSelector((state) => state.customerdata.isSavingData);
    const saveCustomerDone = useSelector((state) => state.customerdata.saveDataDone);
    const saveCustomerFail = useSelector((state) => state.customerdata.saveDataFailed);
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const removeCustomerStatus = useSelector((state) => state.customerdata.removeCustomerStatus);
    const parameters = useSelector((state) => state.parameterdata.parameters);
    const contracts = useSelector((state) => state.contractdata.contractsById);
    const duplicates = useSelector((state) => state.duplicatedata.duplicates);
    const { t } = useTranslation();

    const doCustomerSave = (customer) => {
        return dispatch(saveCustomer(customer));
    };

    const doCustomerRemove = (customer) => {
        return dispatch(removeCustomer(customer));
    };

    const checkDuplicates = (id, customerId, name, businessId) => {
        dispatch(getDuplicates(id, customerId, name, businessId));
    };

    if (id !== 'new' && customersById[id] == null && !getCustomerByIdInProgress && !getCustomerByIdFailed) {
        dispatch(getCustomerFull(id));
    }

    if (props.getCustomerByIdInProgress) {
        return <Spinner padding="20px" title={t('general.loading')} />;
    } else if (!props.getCustomerByIdInProgress && props.getCustomerByIdFailed) {
        return (
            <div style={{ padding: '20px' }}>
                <Alert severity="error">{t('customer.failedToRetrieveCustomerDataRetry')}</Alert>
            </div>
        );
    }

    return (
        <CustomerEditView
            history={props.history}
            userProfile={userProfile}
            customer={customersById[id]}
            savingCustomer={savingCustomer}
            saveCustomerDone={saveCustomerDone}
            saveCustomerFail={saveCustomerFail}
            saveCustomer={doCustomerSave}
            removeCustomer={doCustomerRemove}
            newCustomer={id === 'new'}
            customerRemoveStatus={removeCustomerStatus[id]}
            parameters={parameters}
            contracts={contracts}
            checkDuplicates={checkDuplicates}
            duplicates={duplicates}
        />
    );
}
